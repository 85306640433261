// src/store/slices/layoutSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    headerHeight: 0,
  },
  reducers: {
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    },
  },
});

// Exporting the actions
export const { setHeaderHeight } = layoutSlice.actions;

// Default export the reducer
export default layoutSlice.reducer;
