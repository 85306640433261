// path: zarabyte/zarabyte-react/src/backend/userSettings.js

import { request } from './request';

// Function to get all available settings
const getAllSettings = async () => {
  const response = await request('GET', '/user-settings/admin/all-settings');
  if (!response.ok) {
    throw new Error('Failed to fetch all settings');
  }
  return response.json();
};

// Function to get user settings by user ID
const getUserSettingsById = async (userId) => {
  const response = await request('GET', `/user-settings/admin/user-settings/${userId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch user settings');
  }
  return response.json();
};

// Function to set a user setting
const setUserSetting = async (userId, settingName, value) => {
  const body = { userId, settingName, value };
  await request('POST', '/user-settings/admin/set-user-setting', body);
};

export {
  getAllSettings,
  getUserSettingsById,
  setUserSetting
};
