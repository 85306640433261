// path: zarabyte/zarabyte-react/src/backend/userJourney.js
import { request } from './request';

// Function to send an email invite for beta
const emailInviteForBeta = async (name, email) => {
  const body = { name, email };
  await request('POST', '/user-journey/email-invite-for-beta', body);
};

// Function to allocate credits to a user
const allocateCredits = async (email, credits) => {
  const body = { email, credits };
  await request('POST', '/user-journey/allocate-credits', body);
};

// Function to allocate credits and send an invite
const allocateCreditsAndInvite = async (name, email, credits) => {
  const body = { name, email, credits };
  await request('POST', '/user-journey/allocate-credits-and-invite', body);
};

// Function to handle user's journey on first login
const handleUserJourney = async () => {
  await request('GET', '/user-journey/myJourney');
  const response = await request('GET', '/user-journey/myJourney');
  if (!response.ok) {
    throw new Error('Failed to fetch user journey');
  }
  return response.json();
};

// Function to get all user journeys
const getAllUserJourneys = async () => {
  const response = await request('GET', '/user-journey/admin/all');
  if (!response.ok) {
    throw new Error('Failed to fetch user journeys');
  }
  return response.json();
};

// Function to get user journey by email
const getUserJourneyByEmail = async (email) => {
  const response = await request('GET', `/user-journey/userJourney/${email}`);
  if (!response.ok) {
    throw new Error('Failed to fetch user journey');
  }
  return response.json();
};

// Function to delete a journey
const deleteJourney = async (journeyId) => {
  const body = { journeyId };
  await request('DELETE', '/user-journey/delete-journey', body);
};

export {
  emailInviteForBeta,
  allocateCredits,
  allocateCreditsAndInvite,
  handleUserJourney,
  getAllUserJourneys,
  getUserJourneyByEmail,
  deleteJourney
};
