//zarabyte/zarabyte-react/src/backend/credit.js
import { request } from './request'; 

const creditAccount = async (userId, amount, description) => {
  const body = { userId, amount, description };
  await request('POST', '/credit/admin/creditAccount', body);
};

const debitAccount = async (userId, amount, description) => {
  const body = { userId, amount, description };
  await request('POST', '/credit/admin/debitAccount', body);
};

const adminCheckCreditBalance = async (userId) => {
  const response = await request('GET', `/credit/admin/checkCreditBalance?userId=${userId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch balance');
  }
  const balance = await response.text(); 
  return balance;
};

const checkCreditBalance = async () => {
    const response = await request('GET', '/credit/checkCreditBalance');
    if (!response.ok) {
      throw new Error('Failed to fetch balance');
    }
    const balance = await response.text(); 
    return balance;
}

export { creditAccount, debitAccount, adminCheckCreditBalance, checkCreditBalance };