import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Box } from '@mui/material';

const ContactList = ({ contacts, onContactSelect }) => {
  const [filterStatus, setFilterStatus] = useState('');

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const filteredContacts = filterStatus
    ? contacts.filter((contact) => contact.status === filterStatus)
    : contacts;

  return (
    <Box>
      <TextField
        label="Filter by Status"
        fullWidth
        margin="normal"
        value={filterStatus}
        onChange={handleFilterChange}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Message</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContacts.map((contact) => (
              <TableRow key={contact._id}>
                <TableCell>{contact.message}</TableCell>
                <TableCell>{contact.status}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => onContactSelect(contact)}>
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ContactList;
