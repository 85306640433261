// zarabyte/zarabyte-react/src/components/TermsOfService/TermsOfService.js
import React from 'react';

const TermsOfService = () => {
    return (
        <div style={{ padding: '20px', lineHeight: '1.6' }}>
            <h1>Terms of Service</h1>
            <p><strong>Last Updated:</strong> 27.6.2024</p>
            <p>Welcome to Zarabyte ("the Site"). By accessing and using the Site, you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the Site.</p>
            
            <h2>1. Acceptance of Terms</h2>
            <p>By using the Site, you agree to these Terms. We reserve the right to change these Terms at any time without prior notice. Continued use of the Site after any changes constitutes your acceptance of the new Terms.</p>
            
            <h2>2. Services</h2>
            <p>The Site provides AI-powered tools for generating customized books and other intellectual content based on user prompts. We reserve the right to modify, suspend, or discontinue any part of the services at any time without prior notice.</p>
            
            <h2>3. User Accounts</h2>
            <p>To use our services, you must create a user account. You must provide accurate and up-to-date information and maintain the confidentiality of your account credentials. You are responsible for all activities that occur under your account.</p>
            
            <h2>4. Intellectual Property</h2>
            <p>All content on the Site, including text, images, graphics, logos, and trademarks, is the property of Zarabyte and is protected by copyright laws. You may not copy, reproduce, distribute, or create derivative works from any content on the Site without our prior written consent.</p>
            
            <h2>5. Limitation of Liability</h2>
            <p>Your use of the Site is at your own risk. The Site is provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the operation or availability of the Site. We are not liable for any damages arising from your use of the Site.</p>
            
            <h2>6. User Conduct</h2>
            <p>You agree not to use the Site for any unlawful or harmful purposes. You must not engage in activities that could harm the Site's operation, including attempts to hack or breach its security.</p>
            
            <h2>7. Termination of Service</h2>
            <p>We reserve the right to terminate or restrict your account or access to the Site if you violate these Terms or engage in unlawful activities.</p>
            
            <h2>8. Indemnification</h2>
            <p>You agree to indemnify and hold harmless Zarabyte, its employees, and representatives from any claims, damages, losses, or expenses arising from your use of the Site or violation of these Terms.</p>
            
            <h2>9. External Links</h2>
            <p>The Site may contain links to external websites. We are not responsible for the content or services provided by external websites and do not endorse them.</p>
            
            <h2>10. Governing Law and Jurisdiction</h2>
            <p>These Terms are governed by and construed in accordance with the laws of [State/Country]. Any disputes arising from these Terms will be subject to the exclusive jurisdiction of the courts of [State/Country].</p>
            
            <h2>11. Privacy Policy</h2>
            <p>Our Privacy Policy, which describes how we collect, use, and protect your personal information, is part of these Terms. By using the Site, you agree to our Privacy Policy.</p>
            
            <h2>12. Credits and Payments</h2>
            <p>Users can purchase credits for accessing certain services on the Site. Currently, credits can be added only by the admin. Users must contact the admin to add credits. The option to purchase credits directly will be available in the future.</p>
            
            <h2>13. Book Content and User Interaction</h2>
            <p>The Site allows users to create and read customized books with continuous content growth. Users can interact with a personal assistant chatbot for book suggestions and modifications. Users are responsible for the content they generate and must ensure it complies with all applicable laws.</p>
            
            <h2>14. Beta Disclaimer</h2>
            <p>Please note that Zarabyte is currently in beta. All content generated on the Site is produced by models from OpenAI. As a beta product, the services may have errors or interruptions, and we appreciate your understanding and feedback as we continue to improve.</p>
            
            <h2>15. Contact Information</h2>
            <p>For questions or comments regarding these Terms, please contact us at: <a href="mailto:support@zarabyte.com">support@zarabyte.com</a>.</p>
        </div>
    );
};

export default TermsOfService;
