//zarabyte/zarabyte-react/src/components/BookRequestManager/BookReader/BookReaderLanguageDetector.js

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { franc } from 'franc';

const BookReaderLanguageDetector = ({ book, setTextDirection }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const text = book.title + ' ' + book.description;
    const detectedLang = franc(text, { minLength: 3 });
    let direction = 'ltr';

    if (detectedLang === 'heb' || detectedLang === 'ara') {
      direction = 'rtl';
      i18n.changeLanguage('he'); // Change this based on detected language
    } else {
      direction = 'ltr';
      i18n.changeLanguage('en'); // Default language
    }

    setTextDirection(direction);
  }, [book.title, book.description, i18n, setTextDirection]);

  return null;
};

export default BookReaderLanguageDetector;
