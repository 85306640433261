//zarabyte/zarabyte-react/src/App.js

import React from 'react';
import Router from './components/Router/Router';
import './i18n';

function App() {
    return <Router />;
}

export default App;
