import { request } from './request';

const handleResponse = async (response) => {
    if (response.ok) {
        return await response.json();
    } else {
        console.error('Request failed:', response.statusText);
        throw new Error(response.statusText);
    }
};

const bookWriterManagerAPI = {
    requestBook: async () => {
        const response = await request('POST', '/book-writers-manager/request-book');
        return handleResponse(response);
    },

    getBookRequestsInfo: async (archived = false) => {
        const url = archived ? '/book-writers-manager/book-requests?archived=true' : '/book-writers-manager/book-requests';
        const response = await request('GET', url);
        return handleResponse(response);
    },

    getBookRequestInfo: async (requestId) => {
        const url = `/book-writers-manager/book-request/${requestId}`;
        const response = await request('GET', url);
        return handleResponse(response);
    },

    archiveRequest: async (requestId) => {
        await request('POST', `/book-writers-manager/archive-request/${requestId}`);
    },

    workOnBookRequest: async (requestId) => {
        const response = await request('POST', `/book-writers-manager/work-on-book-request/${requestId}`);
        return handleResponse(response);
    },

    stopWorkingOnBookRequest: async (requestId) => {
        const response = await request('POST', `/book-writers-manager/stop-working-on-book-request/${requestId}`);
        return handleResponse(response);
    },

    setReadingPointerOnBookRequest: async (requestId, readingPointer) => {
        await request('POST', `/book-writers-manager/reading-pointer/${requestId}`, {readingPointer});
    },

    getBooksStatusProgress: async (requestIds) => {
        const response = await request('POST', '/book-writers-manager/book-requests-status-progress', { requestIds });
        return handleResponse(response);
    },

    getBookRequestsCoverLinks: async (requestIds) => {
        const response = await request('POST', '/book-writers-manager/book-requests-cover-links', { requestIds });
        return handleResponse(response);
    },

    getBookContent: async (requestId) => {
        const response = await request('GET', `/book-writers-manager/book-content/${requestId}`);
        return handleResponse(response);
    },

    fetchStatusProgressIfNeeded: async (bookRequests) => {
        const ids = bookRequests.filter(br => br.status !== 'ready').map(br => br._id);
        if (ids.length > 0) {
            const response = await bookWriterManagerAPI.getBooksStatusProgress(ids);
            return response;
        }
        return [];
    },

    fetchCoverImagesIfNeeded: async (bookRequests) => {
        const ids = bookRequests.filter(br => !br.book?.coverImagePath).map(br => br._id);
        if (ids.length > 0) {
            const response = await bookWriterManagerAPI.getBookRequestsCoverLinks(ids);
            return response;
        }
        return [];
    },

    getSectionSpeech: async (requestId, partIndex, chapterIndex, sectionIndex) => {
        const url = `/book-writers-manager/section-speech/${requestId}/${partIndex}/${chapterIndex}/${sectionIndex}`;
        const response = await request('GET', url);
        return handleResponse(response);
    }
};

export default bookWriterManagerAPI;