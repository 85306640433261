// Relative Path: src/components/BookRequestManager/BookReader/BookReader.js

import React, { useState, useCallback } from 'react';
import { Container, Typography, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import BookContent from './BookContent/BookContent';
import BookReaderDrawer from './BookReaderDrawer';
import BookReaderScrollHandler from './BookReaderScrollHandler';
import BookReaderLanguageDetector from './BookReaderLanguageDetector';

const BookReader = ({ book, isLoading, onLoad, onUpdateReadingPointer, containerRef, setCurrentIndices, currentIndices }) => {
  const [isBookmarkOpen, setIsBookmarkOpen] = useState(false);
  const [textDirection, setTextDirection] = useState('ltr');
  const headerHeight = useSelector((state) => state.layout.headerHeight);

  const selectChapter = useCallback((partIndex, chapterIndex, sectionIndex = 0) => {
    if (partIndex === null || chapterIndex === null) return;
    setCurrentIndices([partIndex, chapterIndex, sectionIndex]);
    
    const chapterElement = document.getElementById(`chapter-${partIndex}-${chapterIndex}-${sectionIndex}`);
    if (chapterElement) {
      chapterElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [setCurrentIndices]);

  return (
    <div style={{ display: 'flex', direction: textDirection }}>
      <BookReaderDrawer
        book={book}
        isBookmarkOpen={isBookmarkOpen}
        currentPartIndex={currentIndices[0]}
        currentChapterIndex={currentIndices[1]}
        selectChapter={selectChapter}
        headerHeight={headerHeight}
        onToggle={() => setIsBookmarkOpen(!isBookmarkOpen)}
        onClose={() => setIsBookmarkOpen(false)}
      />
      <div
        style={{
          flexGrow: 1,
          marginTop: headerHeight,
          direction: textDirection,
        }}
      >
        <Container>
          {book.title && (
            <Typography variant="h4" gutterBottom style={{ marginTop: '16px' }}>
              {book.title}
            </Typography>
          )}
          {book.description && (
            <Typography variant="body1" gutterBottom>
              {book.description}
            </Typography>
          )}
          {book.coverImagePath && (
            <img src={book.coverImagePath} alt="Cover" style={{ width: '100%', marginBottom: '16px' }} />
          )}
          <BookContent
            book={book}
            currentPartIndex={currentIndices[0]}
            currentChapterIndex={currentIndices[1]}
            currentSectionIndex={currentIndices[2]}
          />
          {isLoading && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
              <CircularProgress />
            </div>
          )}
        </Container>
      </div>
      <BookReaderScrollHandler
        containerRef={containerRef}
        book={book}
        isLoading={isLoading}
        onLoad={onLoad}
        setCurrentIndices={setCurrentIndices}
        selectChapter={selectChapter}
      />
      <BookReaderLanguageDetector
        book={book}
        setTextDirection={setTextDirection}
      />
    </div>
  );
};

export default BookReader;
