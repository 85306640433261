import React from 'react';
import { Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import technologyIcon from '../../../../assets/technology-image.webp';
import { useNavigate } from 'react-router-dom';

const Technology = () => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{ maxWidth: 345, mx: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}
      role="region"
      aria-labelledby="technology-section-title"
    >
      <CardMedia
        component="img"
        height="120"
        image={technologyIcon}
        alt="Our Technology"
      />
      <CardContent>
        <Typography id="technology-section-title" gutterBottom variant="h5" component="div" sx={{ fontSize: '1.2rem' }}>
          Our Technology
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1rem' }}>
          At Zarabyte, we use advanced AI models, including GPT-4, to generate dynamic and personalized content. This technology supports hierarchical structures for a seamless reading experience.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/technology')}
          sx={{ marginTop: '10px' }}
          aria-label="Read more about our technology"
        >
          Read More
        </Button>
      </CardContent>
    </Card>
  );
};

export default Technology;
