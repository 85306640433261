export const calculateProgress = (bookProgress) => {
    return(calculateDivisionProgress(bookProgress.partsProgress, 
      calculateDivisionProgress(bookProgress.chaptersProgress,
        calculateDivisionProgress(bookProgress.sectionsProgress)))) * 100;
  };

  const calculateDivisionProgress = (progress, resedue) => {
    if(!progress) return 0;
    let completed = progress.index + 1;
    const total = progress.ofLength + 1;

    if(resedue) completed += resedue;
    const result = completed / total;
    return Math.min(result, 1);
  }