// Relative Path: src/components/BookRequestManager/BookReader/BookReaderScrollHandler.js

import { useEffect, useCallback, useState } from 'react';
import { throttle } from 'lodash'; // Import lodash for throttling

const BookReaderScrollHandler = ({
  containerRef,
  book,
  isLoading,
  onLoad,
  setCurrentIndices,
  selectChapter,
}) => {
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const checkBottomAndLoad = useCallback(() => {
    if (containerRef.current && !isLoading) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const bottomThreshold = 700; // Load more content when within 700px of the bottom

      if (scrollHeight - scrollTop - clientHeight < bottomThreshold) {
        onLoad();
      }
    }
  }, [isLoading, onLoad, containerRef]);

  const checkVisibleSection = useCallback(() => {
    const parts = book.parts || [];

    for (let partIndex = 0; partIndex < parts.length; partIndex++) {
      const part = parts[partIndex];
      if (!part.chapters) continue;

      for (let chapterIndex = 0; chapterIndex < part.chapters.length; chapterIndex++) {
        const chapter = part.chapters[chapterIndex];
        if (!chapter.sections) continue;

        for (let sectionIndex = 0; sectionIndex < chapter.sections.length; sectionIndex++) {
          const chapterElement = document.getElementById(`chapter-${partIndex}-${chapterIndex}-${sectionIndex}`);
          if (chapterElement) {
            const rect = chapterElement.getBoundingClientRect();
            const isTopVisible = rect.top >= 0 && rect.top < window.innerHeight;
            const isBottomVisible = rect.bottom >= 0 && rect.bottom < window.innerHeight;
            const isVisible = isTopVisible || isBottomVisible;

            if (isVisible) {
              setCurrentIndices([partIndex, chapterIndex, sectionIndex]);
              return; // Stop checking after finding the first visible section
            }
          }
        }
      }
    }

    // Ensure we check if we need to load more content after checking visibility
    checkBottomAndLoad();
  }, [book.parts, setCurrentIndices, checkBottomAndLoad]);

  const handleScroll = useCallback(
    throttle(() => {
      if (containerRef.current) {
        const { scrollTop } = containerRef.current;
        if (scrollTop !== lastScrollTop) {
          checkVisibleSection();
          checkBottomAndLoad(); // Explicitly call checkBottomAndLoad on each scroll event
          setLastScrollTop(scrollTop);
        }
      }
    }, 200), // Adjust the throttling interval as needed
    [lastScrollTop, containerRef, book.parts, setCurrentIndices, isLoading, onLoad]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, containerRef]);

  useEffect(() => {
    if (book && !firstLoaded) {
      if (book.readingPointer) {
        selectChapter(book.readingPointer[0], book.readingPointer[1], book.readingPointer[2]);
      }
      checkBottomAndLoad();
      setFirstLoaded(true);
    }
  }, [book, firstLoaded, selectChapter, checkBottomAndLoad]);

  return null;
};

export default BookReaderScrollHandler;
