// zarabyte/zarabyte-react/src/store/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userDetails: undefined,
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setUserJourney: (state, action) => {
      if (state.userDetails) {
        state.userDetails.journey = action.payload;
      }
    },
  },
});

// Exporting the actions
export const { setUserDetails, setUserJourney } = userSlice.actions;
// Default export the reducer
export default userSlice.reducer;