//zarabyte/zarabyte-react/src/components/BookRequestManager/BookReader/BookContent/BookContent.js

import React from 'react';
import BookContentPart from './BookContentPart';

const BookContent = ({ book, currentPartIndex, currentChapterIndex, currentSectionIndex }) => {
  const hasMultipleParts = book.parts && book.parts.length > 1;
  return (
    <div>
      {book.parts?.map((part, partIndex) => (
        <BookContentPart
          key={part.title}
          part={part}
          partIndex={partIndex}
          currentPartIndex={currentPartIndex}
          currentChapterIndex={currentChapterIndex}
          currentSectionIndex={currentSectionIndex}
          hasMultipleParts={hasMultipleParts}
        />
      ))}
    </div>
  );
};

export default BookContent;
