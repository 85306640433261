// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#747FAF', // Example primary color
    },
    secondary: {
      main: '#5A8E89', // Example secondary color
    },
    // Add more customization as needed
  },
  typography: {
    // Define your typography adjustments here
  },
  // Add other theme customizations here
});

export default theme;