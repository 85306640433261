// Relative Path: src/components/AssistantThread/AssistantThread.js
import React, { useState, useEffect, useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import assistantsApi from '../../backend/assistantThread';
import Chat from './Chat';

const AssistantThread = ({ threadId, onResponse, isVisible, chatHeight, chatWidth, disableClose }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(isVisible);

  const fetchMessages = useCallback(async () => {
    try {
      const data = await assistantsApi.getMessages(threadId);
      setMessages(data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  }, [threadId]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const handleSendMessage = async (inputValue) => {
    setIsLoading(true);
    try {
      const message = await assistantsApi.postMessage(threadId, inputValue);
      setMessages(current => [...current, message]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
    handleGetResponse();
  };

  const handleGetResponse = async () => {
    setIsLoading(true);
    try {
      const newMessages = await assistantsApi.getAssistantResponse(threadId);
      setMessages(current => [...current, ...newMessages]);
      onResponse();
    } catch (error) {
      console.error('Error getting response', error);
    }
    setIsLoading(false);
  };

  const toggleChatVisibility = () => {
    setIsChatVisible(!isChatVisible);
  };

  return (
    <>
      {!disableClose && (
        <IconButton
          onClick={toggleChatVisibility}
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 1300,
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '50%',
            width: '56px',
            height: '56px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          }}
          color="primary"
        >
          <ChatIcon />
          {isChatVisible ? <RemoveIcon sx={{ position: 'absolute', bottom: 8, right: 8, fontSize: '16px' }} /> : <AddIcon sx={{ position: 'absolute', bottom: 8, right: 8, fontSize: '16px' }} />}
        </IconButton>
      )}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: chatWidth,
          height: chatHeight,
          zIndex: 1000,
          display: isChatVisible ? 'block' : 'none',
        }}
      >
        <Box
          sx={{
            height: `calc(100% - 60px)`,
            backgroundColor: 'white',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
          }}
        >
          <Chat messages={messages} isLoading={isLoading} onSendMessage={handleSendMessage} />
        </Box>
      </Box>
    </>
  );
};

export default AssistantThread;
