//zarabyte/zarabyte-react/src/components/Router/Router.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { CircularProgress, Box } from '@mui/material';
import theme from '../../theme';
import AdminPage from '../AdminPage/AdminPage';
import Header from '../Header/Header';
import BookRequestsManager from '../BookRequestsManager/BookRequestsManager';
import BookRequestManager from '../BookRequestManager/BookRequestManager';
import WelcomePage from '../guestPages/WelcomePage/WelcomePage';
import ContactUs from '../ContactUs/ContactUs';
import AboutPage from '../guestPages/AboutPage/AboutPage';
import ProductPage from '../guestPages/ProductPage/ProductPage';
import TechnologyPage from '../guestPages/TechnologyPage/TechnologyPage';
import FeaturesPage from '../guestPages/FeaturesPage/FeaturesPage';
import FAQsPage from '../guestPages/FAQsPage/FAQsPage';
import TermsOfService from '../guestPages/TermsOfService/TermsOfService';
import PrivacyPolicy from '../guestPages/PrivacyPolicy/PrivacyPolicy';
import SignIn from '../guestPages/auth/SignIn/SignIn';
import SignUp from '../guestPages/auth/SignUp/SignUp';
import Footer from '../guestPages/Footer/Footer';
import ScrollToTop from '../../ScrollToTop';

const Router = () => {
  const userDetails = useSelector((state) => state.user.userDetails);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userDetails !== undefined) {
      setIsLoading(false);
    }
  }, [userDetails]);

  const routes = [
    { path: '/', element: <WelcomePage />, private: false },
    { path: '/about', element: <AboutPage />, private: false },
    { path: '/product', element: <ProductPage />, private: false },
    { path: '/technology', element: <TechnologyPage />, private: false },
    { path: '/features', element: <FeaturesPage />, private: false },
    { path: '/FAQs', element: <FAQsPage />, private: false },
    { path: '/terms-of-service', element: <TermsOfService />, private: false },
    { path: '/books', element: <BookRequestsManager />, private: true },
    { path: '/books/archive', element: <BookRequestsManager isArchive={true} />, private: true },
    { path: '/books/:requestId', element: <BookRequestManager />, private: true },
    { path: '/contact', element: <ContactUs />, private: false },
    { path: '/admin', element: <AdminPage />, private: true, adminOnly: true },
    { path: '/privacy-policy', element: <PrivacyPolicy />, private: false },
    { path: '/signin', element: <SignIn />, private: false },
    { path: '/signup', element: <SignUp />, private: false },
  ];

  const renderRouteElement = (element, isPrivate, adminOnly) => {
    if (isPrivate) {
      if (!userDetails) {
        return <Navigate replace to="/signin" />;
      }
      if (adminOnly && userDetails.role !== 'admin') {
        return <Navigate replace to="/" />;
      }
    }
    return element;
  };

  const renderRoutes = () => (
    <Routes>
      {routes.map(({ path, element, private: isPrivate, adminOnly }) => (
        <Route
          key={path}
          path={path}
          element={renderRouteElement(element, isPrivate, adminOnly)}
        />
      ))}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header />
          <div style={{ flex: 1, paddingTop: '64px', display: 'flex', flexDirection: 'column' }}>
            {isLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
              </Box>
            ) : (
              renderRoutes()
            )}
          </div>
          {!userDetails && !isLoading && <Footer />}
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default Router;