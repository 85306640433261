// src/components/Header/UserMenu.js
import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Tooltip, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BookIcon from '@mui/icons-material/Book';
import ArchiveIcon from '@mui/icons-material/Archive';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import InfoIcon from '@mui/icons-material/Info';
import ListIcon from '@mui/icons-material/List';
import ScienceIcon from '@mui/icons-material/Science';
import StarIcon from '@mui/icons-material/Star';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'; 
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UserMenu = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user.userDetails);

  const handleMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleClose();
  };

  const userMenuItems = [
    <MenuItem key="books" onClick={() => handleNavigate('/books')}>
      <BookIcon sx={{ marginRight: '8px' }} /> Books
    </MenuItem>,
    <MenuItem key="archive" onClick={() => handleNavigate('/books/archive')}>
      <ArchiveIcon sx={{ marginRight: '8px' }} /> Archive
    </MenuItem>,
    userDetails?.role === 'admin' && (
      <MenuItem key="admin" onClick={() => handleNavigate('/admin')}>
        <AdminPanelSettingsIcon sx={{ marginRight: '8px' }} /> Admin
      </MenuItem>
    ),
    <MenuItem key="faqs" onClick={() => handleNavigate('/faqs')}>
      <QuestionAnswerIcon sx={{ marginRight: '8px' }} /> FAQs
    </MenuItem>,
    <MenuItem key="contact" onClick={() => handleNavigate('/contact')}>
      <ContactMailIcon sx={{ marginRight: '8px' }} /> Contact
    </MenuItem>
  ];

  const guestMenuItems = [
    <MenuItem key="welcome" onClick={() => handleNavigate('/')}>
      <MenuIcon sx={{ marginRight: '8px' }} /> Welcome
    </MenuItem>,
    <MenuItem key="about" onClick={() => handleNavigate('/about')}>
      <InfoIcon sx={{ marginRight: '8px' }} /> About Us
    </MenuItem>,
    <MenuItem key="product" onClick={() => handleNavigate('/product')}>
      <ListIcon sx={{ marginRight: '8px' }} /> Product
    </MenuItem>,
    <MenuItem key="technology" onClick={() => handleNavigate('/technology')}>
      <ScienceIcon sx={{ marginRight: '8px' }} /> Technology
    </MenuItem>,
    <MenuItem key="features" onClick={() => handleNavigate('/features')}>
      <StarIcon sx={{ marginRight: '8px' }} /> Features
    </MenuItem>,
    <MenuItem key="faqs" onClick={() => handleNavigate('/faqs')}>
      <QuestionAnswerIcon sx={{ marginRight: '8px' }} /> FAQs
    </MenuItem>,
    <MenuItem key="contact" onClick={() => handleNavigate('/contact')}>
      <ContactMailIcon sx={{ marginRight: '8px' }} /> Contact Us
    </MenuItem>
  ];

  return (
    <>
      <Tooltip title="User Menu">
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handleMenu}>
          {userDetails?.avatarUrl ? (
            <Avatar src={userDetails.avatarUrl} />
          ) : (
            <MenuIcon />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={menuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
      >
        {userDetails ? userMenuItems : guestMenuItems}
      </Menu>
    </>
  );
};

export default UserMenu;
