// zarabyte/zarabyte-react/src/components/guestPages/AboutPage/AboutPage.js
import React from 'react';
import { Typography, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';
import missionImage from '../../../assets/mission-image.webp';
import myPhoto from '../../../assets/my-photo.jpeg';

const AboutPage = () => (
  <Box sx={{ padding: '40px 20px', textAlign: 'center', backgroundColor: '#f9f9f9' }}>
    <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '20px' }} component="h1">
      About Us
    </Typography>
    <Grid container spacing={4} justifyContent="center">
      {/* Personal Story */}
      <Grid item xs={12} md={6}>
        <Card 
          sx={{ mx: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }} 
          role="region" 
          aria-labelledby="personal-story-heading"
        >
          <CardMedia
            component="img"
            height="240"
            image={myPhoto}
            alt="Photo of Yaron Gilo"
            sx={{ width: 'auto', height: '240px', borderRadius: '8px', margin: 'auto', marginTop: '20px' }}
          />
          <CardContent>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'bold' }} 
              id="personal-story-heading" 
              component="h2"
            >
              Hello! I'm Yaron Gilo
            </Typography>
            <Typography variant="body1" paragraph>
              Zarabyte began from my passion for developing and inventing new things. As both a developer and an R&D team leader, I saw the immense potential in AI, especially with OpenAI's advanced language models. This vision led to the creation of Zarabyte, a platform that transforms the idea of personalized reading into a reality.
            </Typography>
            <Typography variant="body1" paragraph>
              I believe that every reader is unique, and their reading content should be too. Zarabyte aims to provide a reading experience that evolves with you, adapting to your preferences and making each session engaging and personal.
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: 'right', fontStyle: 'italic', fontWeight: 'bold' }}>
              Yaron Gilo,<br />Founder
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {/* Mission and Future Plans */}
      <Grid item xs={12} md={6}>
        <Card 
          sx={{ mx: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }} 
          role="region" 
          aria-labelledby="mission-heading"
        >
          <CardMedia
            component="img"
            height="240"
            image={missionImage}
            alt="Image representing our mission"
            sx={{ width: 'auto', height: '240px', borderRadius: '8px', margin: 'auto', marginTop: '20px' }}
          />
          <CardContent>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'bold' }} 
              id="mission-heading" 
              component="h2"
            >
              Our Mission
            </Typography>
            <Typography variant="body1" paragraph>
              At Zarabyte, our mission is to make reading a personalized and evolving experience for everyone. Using advanced AI, we create content that adjusts to your reading style and preferences, making every book uniquely yours.
            </Typography>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'bold', marginTop: '20px' }} 
              id="beta-heading" 
              component="h3"
            >
              Currently in Beta
            </Typography>
            <Typography variant="body1" paragraph>
              Zarabyte is currently in its beta stage, inviting selected users to explore and provide feedback on this innovative approach. We are enhancing the platform, improving content quality, and expanding features like audiobook support and personalized settings.
            </Typography>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'bold', marginTop: '20px' }} 
              id="future-plans-heading" 
              component="h3"
            >
              Future Plans
            </Typography>
            <Typography variant="body1" paragraph>
              Looking ahead, we aim to optimize our technology for cost-effectiveness, enhance content quality, and add new functionalities. Our vision is to offer a comprehensive, personalized reading experience, including support for audiobooks and other content types.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Box>
);

export default AboutPage;
