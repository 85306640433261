// Relative Path: src/components/BookRequestManager/BookRequestManager.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import bookWriterManagerAPI from '../../backend/bookWriterManager';
import BookRequest from './BookRequest';

const BookRequestManager = () => {
  const { requestId } = useParams();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [book, setBook] = useState(undefined);
  const [requestInfo, setRequestInfo] = useState(undefined);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusBarHeight, setStatusBarHeight] = useState(0);
  const [currentIndices, setCurrentIndices] = useState([null, null, null]);
  const headerHeight = useSelector((state) => state.layout.headerHeight);
  const navigate = useNavigate();
  const previousPointerRef = useRef([null, null, null]);
  const currentPointerRef = useRef(currentIndices);

  const handleFetchRequest = useCallback(async () => {
    try {
      const data = await bookWriterManagerAPI.getBookRequestInfo(requestId);
      setRequestInfo(data);

      if (isFirstLoad) {
        if (!data.book) setIsChatVisible(true);
        setIsFirstLoad(false);
      }

      setIsLoading(data.status === 'working');
    } catch (error) {
      console.error('Failed to fetch request info:', error);
      setRequestInfo(null);
      setIsLoading(false);
    }
  }, [requestId, isFirstLoad]);

  const handleFetchBookContent = useCallback(async () => {
    try {
      const data = await bookWriterManagerAPI.getBookContent(requestId);
      setBook(data);
    } catch (error) {
      console.error('Failed to fetch book content:', error);
      setBook(null);
    }
  }, [requestId]);

  const handleStart = async () => {
    try {
      await bookWriterManagerAPI.workOnBookRequest(requestId);
      handleFetchRequest();
    } catch (error) {
      console.error('Failed to start work on book request:', error);
    }
  };

  const handleClose = () => navigate('/books');

  const handleUpdateReadingPointer = useCallback(async (readingPointer) => {
    try {
      await bookWriterManagerAPI.setReadingPointerOnBookRequest(requestId, readingPointer);
    } catch (error) {
      console.error('Failed to update reading pointer:', error);
    }
  }, [requestId]);

  const getStatusText = () => {
    if (!requestInfo) return 'Fetching status...';

    if (!requestInfo.book && !requestInfo.userRequest) return 'Book not started';
    if (!requestInfo.book && requestInfo.userRequest) return 'Ready to start writing';
    if (!requestInfo.book && requestInfo.status === 'working') return 'Book is preparing';
    if (requestInfo.status === 'ready') return 'Done';

    return requestInfo.progress || 'Status unknown';
  };

  const isStartButtonVisible = () => {
    return !requestInfo?.book && requestInfo?.userRequest && requestInfo.status !== 'working';
  };

  const updatePointerIfNeeded = useCallback(() => {
    const [currentPart, currentChapter, currentSection] = currentPointerRef.current;
    if (currentPart !== null && currentChapter !== null && currentSection !== null) {
      if (previousPointerRef.current[0] !== currentPart ||
          previousPointerRef.current[1] !== currentChapter ||
          previousPointerRef.current[2] !== currentSection) {
        handleUpdateReadingPointer(currentPointerRef.current);
        previousPointerRef.current = currentPointerRef.current;
      }
    }
  }, [handleUpdateReadingPointer]);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(handleFetchRequest, 5000);
    }
    return () => clearInterval(interval);
  }, [isLoading, handleFetchRequest]);

  useEffect(() => {
    handleFetchRequest();
  }, [handleFetchRequest]);

  useEffect(() => {
    if (requestInfo && requestInfo.book) {
      handleFetchBookContent();
    }
  }, [requestInfo, handleFetchBookContent]);

  useEffect(() => {
    currentPointerRef.current = currentIndices;
  }, [currentIndices]);

  useEffect(() => {
    const interval = setInterval(updatePointerIfNeeded, 120000);

    return () => {
      clearInterval(interval);
      updatePointerIfNeeded();
    };
  }, [updatePointerIfNeeded]);

  return (
    <BookRequest
      headerHeight={headerHeight}
      statusBarHeight={statusBarHeight}
      setStatusBarHeight={setStatusBarHeight}
      isLoading={isLoading}
      isChatVisible={isChatVisible}
      requestInfo={requestInfo}
      book={book}
      handleFetchRequest={handleFetchRequest}
      handleStart={handleStart}
      handleClose={handleClose}
      handleUpdateReadingPointer={handleUpdateReadingPointer}
      getStatusText={getStatusText}
      isStartButtonVisible={isStartButtonVisible}
      setCurrentIndices={setCurrentIndices}
      currentIndices={currentIndices}
    />
  );
};

export default BookRequestManager;
