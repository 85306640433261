// Relative Path: src/components/BookRequestManager/BookReader/BookReaderDrawer.js

import React from 'react';
import { Drawer, IconButton } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import BookMark from './BookMark/BookMark';

const BookReaderDrawer = ({ book, isBookmarkOpen, currentPartIndex, currentChapterIndex, selectChapter, headerHeight, onToggle, onClose }) => (
  <div className="book-reader-drawer">
<IconButton
  onClick={onToggle}
  style={{
    position: 'fixed',
    top: headerHeight + 40,
    left: isBookmarkOpen ? 280 : 0,
    zIndex: 900,
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc', 
    borderRadius: '50%', 
    padding: '10px' 
  }}
>
  {isBookmarkOpen ? <ChevronLeftIcon /> : <FormatListBulletedIcon />}
</IconButton>

    <Drawer
      variant="persistent"
      anchor="left"
      open={isBookmarkOpen}
      onClose={onClose}
      style={{ position: 'fixed', top: headerHeight }}
      className="book-reader-drawer"
    >
      <BookMark
        book={book}
        currentPartIndex={currentPartIndex}
        currentChapterIndex={currentChapterIndex}
        selectChapter={selectChapter}
      />
    </Drawer>
  </div>
);

export default BookReaderDrawer;
