//zarabyte/zarabyte-react/src/backend/request.js

import { fetchAuthSession } from 'aws-amplify/auth';

const ENDPOINT = process.env.REACT_APP_API_URL;

async function getJwtToken() {
  try {
    const session = await fetchAuthSession();
    return session.tokens.idToken;
  } catch (error) {
    throw new Error(`The user is not authenticated`);
  }
}

async function request(method, url, body, headers={}) {
  const token = await getJwtToken();
  const request_params = {
    method: method,
    headers: {
      ...headers,
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  };
  if(body) {
    request_params.body = JSON.stringify(body);
  }
  const response = await fetch(`${ENDPOINT}${url}`, request_params);
  if (!response.ok) {
    const errorBody = await response.text();
    throw new Error(`Network response was not ok: ${response.status} - ${errorBody}`);
  }
  return response;
}

export { request };