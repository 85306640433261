import React, { useState } from 'react';
import { ListItemText, IconButton, Tooltip, ListItemButton, Box, useMediaQuery } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';

const BookMarkChapterItem = ({ chapter, chapterIndex, currentPartIndex, currentChapterIndex, selectChapter, partIndex, handleInfoClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasContent = chapter.sections && chapter.sections.length > 0 && chapter.sections[0].content;
  const isSelected = partIndex === currentPartIndex && chapterIndex === currentChapterIndex;
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setShowTooltip(false);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ paddingLeft: '32px', backgroundColor: isSelected ? '#e0f7fa' : 'transparent' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ListItemButton
        onClick={() => selectChapter(partIndex, chapterIndex)}
        disabled={!hasContent}
        sx={{ flexGrow: 1 }}
      >
        <ListItemText
          primary={chapter.title}
          primaryTypographyProps={{ color: isSelected ? 'textPrimary' : 'textSecondary' }}
        />
      </ListItemButton>
      {!isMobile && showTooltip && (
        <Tooltip title={chapter.description} arrow>
          <IconButton size="small">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}
      {isMobile && (
        <IconButton size="small" onClick={() => handleInfoClick(chapter.description)}>
          <InfoIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default BookMarkChapterItem;
