import React from 'react';
import { Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import featuresIcon from '../../../../assets/ai-assisted-icon.webp';
import { useNavigate } from 'react-router-dom';

const Features = () => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{ maxWidth: 345, mx: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}
      role="region"
      aria-labelledby="features-section-title"
    >
      <CardMedia
        component="img"
        height="120"
        image={featuresIcon}
        alt="Features"
      />
      <CardContent>
        <Typography id="features-section-title" gutterBottom variant="h5" component="div" sx={{ fontSize: '1.2rem' }}>
          Features
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1rem' }}>
          Zarabyte offers personalized book creation, dynamic content updates, hierarchical bookmarks, and AI-assisted content management. Our features ensure a tailored and engaging reading experience.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/features')}
          sx={{ marginTop: '10px' }}
          aria-label="Read more about features"
        >
          Read More
        </Button>
      </CardContent>
    </Card>
  );
};

export default Features;
