// src/components/WelcomePage/WelcomePage.js
import React from 'react';
import HeroSection from './HeroSection/HeroSection';
import About from './About/About';
import Product from './Product/Product';
import Technology from './Technology/Technology';
import Features from './Features/Features';
import FAQs from './FAQs/FAQs';
import { Box, Grid } from '@mui/material';

const WelcomePage = () => (
  <div>
    <HeroSection />
    <Box sx={{ padding: '20px' }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <About />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Product />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Technology />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Features />
        </Grid>
        {/* Full-width FAQs section */}
        <Grid item xs={12}>
          <FAQs />
        </Grid>
      </Grid>
    </Box>
  </div>
);

export default WelcomePage;
