// zarabyte/zarabyte-react/src/components/BookRequestManager/BookReader/BookContent/BookContentChapter.js

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const BookContentChapter = ({ chapter, chapterIndex, partIndex, currentSectionIndex }) => {
  const hasContent = chapter.sections && chapter.sections.length > 0 && chapter.sections[0].content;

  if (!hasContent) {
    return null;
  }

  return (
    <div id={`chapter-${partIndex}-${chapterIndex}`}>
      {chapter.sections.map((section, sectionIndex) => (
        <div key={sectionIndex} id={`chapter-${partIndex}-${chapterIndex}-${sectionIndex}`}>
          <ReactMarkdown
            children={section.content}
            remarkPlugins={[remarkGfm]}
            components={{
              code({node, inline, className, children, ...props}) {
                return (
                  <code
                    style={{ direction: 'ltr', textAlign: 'left' }}
                    className={className}
                    {...props}
                  >
                    {children}
                  </code>
                );
              },
              pre({node, children, ...props}) {
                return (
                  <pre
                    style={{ direction: 'ltr', textAlign: 'left' }}
                    {...props}
                  >
                    {children}
                  </pre>
                );
              }
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default BookContentChapter;
