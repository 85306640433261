// Relative Path: src/components/Assistant/Chat.js
import React, { useRef } from 'react';
import { Box, TextField, IconButton, CircularProgress, Paper } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatMessage from './ChatMessage';

const Chat = ({ messages, isLoading, onSendMessage }) => {
  const [inputValue, setInputValue] = React.useState('');
  const messagesEndRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (inputValue.trim()) {
      onSendMessage(inputValue);
      setInputValue('');
    }
  };

  React.useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ overflowY: 'auto', flexGrow: 1, p: 1 }}>
        {messages.map((msg, index) => (
          <ChatMessage key={index} message={msg} />
        ))}
        <div ref={messagesEndRef} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, backgroundColor: 'white', borderRadius: '10px', p: 1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
        <TextField
          multiline
          maxRows={4}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
          variant="outlined"
          fullWidth
          disabled={isLoading}
          sx={{ '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
        />
        <IconButton
          color="primary"
          onClick={sendMessage}
          disabled={isLoading || !inputValue.trim()}
          sx={{ ml: 1 }}
        >
          <SendIcon />
        </IconButton>
        {isLoading && (
          <CircularProgress size={24} sx={{ ml: 1 }} />
        )}
      </Box>
    </Box>
  );
};

export default Chat;
