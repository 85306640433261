import React, { useState, useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserDetails } from '../../../store/slices/userSlice';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { handleUserJourney } from '../../../backend/userJourney';
import ClipLoader from 'react-spinners/ClipLoader';

const AuthChecker = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          const session = await fetchAuthSession();
          const idToken = session.tokens.idToken;
          const role = idToken.payload['cognito:groups'] && idToken.payload['cognito:groups'][0];

          const journey = await handleUserJourney();

          dispatch(setUserDetails({ role, name: idToken.payload['name'] || user.username, journey }));
          navigate('/books');
        }
      } catch (error) {
        console.error('User check error:', error);
      } finally {
        setLoading(false);
      }
    };

    checkUser();
  }, [dispatch, navigate]);

  return loading ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <ClipLoader size={50} color={"#123abc"} loading={loading} />
    </div>
  ) : null;
};

const AuthHandler = ({ initialState }) => {
  return (
    <Authenticator initialState={initialState}>
      <AuthChecker />
    </Authenticator>
  );
};

export default AuthHandler;
