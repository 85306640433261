import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, TextField, Button } from '@mui/material';
import { allocateCreditsAndInvite } from '../../backend/userJourney';

const UserJourneyList = ({ journeys = [], onJourneySelect, onJourneyCreated }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [credits, setCredits] = useState(0);

  const handleCreateJourney = async () => {
    try {
      await allocateCreditsAndInvite(name, email, credits);
      alert('Journey created and email sent successfully');
      setEmail('');
      setName('');
      setCredits(0);
      onJourneyCreated();  // Trigger journey list update
    } catch (error) {
      alert('Failed to create journey: ' + error.message);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Create Admin-Initiated Journey</Typography>
      <TextField
        label="Name"
        fullWidth
        margin="normal"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="Email"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Allocated Credits"
        type="number"
        fullWidth
        margin="normal"
        value={credits}
        onChange={(e) => setCredits(parseInt(e.target.value))}
      />
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleCreateJourney}>
          Create Journey
        </Button>
      </Box>

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>User Journeys</Typography>
      {journeys.length === 0 ? (
        <Typography variant="body1">No user journeys available.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Journey Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Allocated Credits</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {journeys.map((journey) => (
                <TableRow key={journey._id} onClick={() => onJourneySelect(journey)}>
                  <TableCell>{journey.email}</TableCell>
                  <TableCell>{journey.name}</TableCell>
                  <TableCell>{journey.journeyType}</TableCell>
                  <TableCell>{journey.status}</TableCell>
                  <TableCell>{journey.allocatedCredits}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => onJourneySelect(journey)}>
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default UserJourneyList;
