// Relative Path: src/components/BookRequestManager/BookRequestStatusBar.js

import React, { useEffect, useRef } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';

const BookRequestStatusBar = ({ requestInfo, onStart, onHeightChange, getStatusText, isStartButtonVisible }) => {
  const statusBarRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (statusBarRef.current && onHeightChange) {
        const height = statusBarRef.current.clientHeight;
        onHeightChange(height);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, [onHeightChange]);

  return (
    <Box
      ref={statusBarRef}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'white',
        zIndex: 1200,
        borderTop: '1px solid #ddd',
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="body1">
        {getStatusText()}
        {requestInfo?.status === 'working' && <CircularProgress size={20} sx={{ ml: 2 }} />}
      </Typography>
      {isStartButtonVisible() && (
        <Button variant="contained" color="primary" onClick={onStart}>
          Start to write the book
        </Button>
      )}
    </Box>
  );
};

export default BookRequestStatusBar;
