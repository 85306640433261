// zarabyte/zarabyte-react/src/backend/user-contact.js
import { request } from './request'; 

// Function to create a new contact for unauthenticated users
const createUnauthenticatedContact = async (statedName, statedEmail, message) => {
  const body = { statedName, statedEmail, message };
  await request('POST', '/user-contact/new/unauthenticated', body);
};

// Function to create a new contact for authenticated users
const createAuthenticatedContact = async (message) => {
  const body = { message };
  await request('POST', '/user-contact/new/authenticated', body);
};

// Function to get all contacts (admin only)
const getAllContacts = async () => {
  const response = await request('GET', '/user-contact/admin/all');
  if (!response.ok) {
    throw new Error('Failed to fetch contacts');
  }
  const contacts = await response.json();
  return contacts;
};

// Function to get contacts by status (admin only)
const getContactsByStatus = async (status) => {
  const response = await request('GET', `/user-contact/admin/by-status?status=${status}`);
  if (!response.ok) {
    throw new Error('Failed to fetch contacts');
  }
  const contacts = await response.json();
  return contacts;
};

// Function to update contact status (admin only)
const updateContactStatus = async (contactId, status) => {
  const body = { status };
  await request('PATCH', `/user-contact/admin/update-status/${contactId}`, body);
};

// Function to update support comments (admin only)
const updateSupportComments = async (contactId, comments) => {
  const body = { comments };
  await request('PATCH', `/user-contact/admin/update-comments/${contactId}`, body);
};

export {
  createUnauthenticatedContact,
  createAuthenticatedContact,
  getAllContacts,
  getContactsByStatus,
  updateContactStatus,
  updateSupportComments
};
