// zarabyte/zarabyte-react/src/components/guestPages/Footer/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer style={{ textAlign: 'center', padding: '20px', background: '#f1f1f1', marginTop: '20px' }}>
    <p>&copy; 2024 Zarabyte. All rights reserved.</p>
    <p>
      For questions or comments, please contact us at: 
      <a href="mailto:support@zarabyte.com" aria-label="Email support at Zarabyte"> support@zarabyte.com</a>
    </p>
    <p>
      Follow us on 
      <a 
        href="https://www.linkedin.com/company/103638978" 
        target="_blank" 
        rel="noopener noreferrer" 
        aria-label="Follow us on LinkedIn"
      > LinkedIn</a>
    </p>
    <p>
      By using this site, you agree to our <Link to="/terms-of-service" aria-label="Read our Terms of Service">Terms of Service</Link>.
    </p>
    <p>
      By using this site, you agree to our <Link to="/privacy-policy" aria-label="Read our Privacy Policy">Privacy Policy</Link>.
    </p>
  </footer>
);

export default Footer;
