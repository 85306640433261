//zarabyte/zarabyte-react/src/components/Header/Header.js

import React, { useEffect, useRef, useCallback } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from "../../assets/logo.svg";
import { signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { useSelector, useDispatch } from 'react-redux';
import { setUserDetails } from '../../store/slices/userSlice';
import { setHeaderHeight } from '../../store/slices/layoutSlice';
import UserMenu from './UserMenu';
import CreditBalance from './CreditBalance';
import UserSection from './UserSection';

const allowedPathsForUser = [
  '/books',
  '/books/archive',
  '/admin',
  '/faqs',
  '/contact'
];

function Header() {
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  const location = useLocation();

  const checkUser = useCallback(async () => {
    try {
      const user = await getCurrentUser();
      if (user) {
        const session = await fetchAuthSession();
        const idToken = session.tokens.idToken;
        const role = idToken.payload['cognito:groups'] && idToken.payload['cognito:groups'][0];
        dispatch(setUserDetails({ role, name: idToken.payload['name'] || user.username }));
      } else {
        dispatch(setUserDetails(null));
      }
    } catch (error) {
      dispatch(setUserDetails(null));
      console.error('User check error:', error);
    }
  }, [dispatch]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  useEffect(() => {
    if (userDetails) {
      const isAllowedPath = allowedPathsForUser.some(path => location.pathname.startsWith(path));
      if (!isAllowedPath && !location.pathname.startsWith('/book/')) { // Include '/book/' in allowed paths for specific book pages
        navigate('/books');
      }
    }
  }, [userDetails, location.pathname, navigate]);

  useEffect(() => {
    const updateHeight = () => {
      if (headerRef.current) {
        const height = headerRef.current.clientHeight;
        dispatch(setHeaderHeight(height));
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, [dispatch]);

  const handleLogoutClick = async () => {
    try {
      await signOut();
      dispatch(setUserDetails(null));
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AppBar position="fixed" ref={headerRef}>
      <Toolbar>
        <img src={Logo} alt="Zarabyte Logo" style={{ marginRight: '20px', height: '50px' }} />
        <UserMenu />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
        {userDetails && <CreditBalance />}
        <UserSection userDetails={userDetails} handleLogoutClick={handleLogoutClick} />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
