//zarabyte/zarabyte-react/src/components/BookRequestManager/BookReader/BookContent/BookContentPart.js

import React from 'react';
import { Typography } from '@mui/material';
import BookContentChapter from './BookContentChapter';

const BookContentPart = ({ part, partIndex, currentPartIndex, currentChapterIndex, currentSectionIndex, hasMultipleParts }) => {
  const hasValidChapters = part.chapters?.some(
    chapter => chapter.sections && chapter.sections.length > 0 && chapter.sections[0].content
  );

  if (!hasValidChapters) {
    return null;
  }

  return (
    <div>
      {hasMultipleParts && (
        <Typography variant="h4" gutterBottom>
          {part.title}
        </Typography>
      )}
      {part.chapters?.map((chapter, chapterIndex) => (
        <BookContentChapter
          key={chapter.title}
          chapter={chapter}
          chapterIndex={chapterIndex}
          partIndex={partIndex}
          currentChapterIndex={currentChapterIndex}
          currentSectionIndex={currentSectionIndex}
        />
      ))}
    </div>
  );
};

export default BookContentPart;
