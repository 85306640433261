import React from 'react';
import { Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import productIcon from '../../../../assets/product-image.webp';
import { useNavigate } from 'react-router-dom';

const Product = () => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{ maxWidth: 345, mx: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}
      role="region"
      aria-labelledby="product-section-title"
    >
      <CardMedia
        component="img"
        height="120"
        image={productIcon}
        alt="Zarabyte Books"
      />
      <CardContent>
        <Typography id="product-section-title" gutterBottom variant="h5" component="div" sx={{ fontSize: '1.2rem' }}>
          Zarabyte Books
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1rem' }}>
          Zarabyte Books allows you to create personalized books tailored to your preferences. Our platform dynamically generates content as you read, ensuring a unique and evolving experience.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/product')}
          sx={{ marginTop: '10px' }}
          aria-label="Read more about Zarabyte Books"
        >
          Read More
        </Button>
      </CardContent>
    </Card>
  );
};

export default Product;
