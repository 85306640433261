// path: zarabyte/zarabyte-react/src/components/UserSettingsManager.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Switch, FormControlLabel, TextField, Button } from '@mui/material';
import { getAllSettings, getUserSettingsById, setUserSetting } from '../../backend/userSettings';

const UserSettingsManager = ({ userId }) => {
  const [allSettings, setAllSettings] = useState({});
  const [userSettings, setUserSettings] = useState({});
  const [dirtySettings, setDirtySettings] = useState({});
  const [originalSettings, setOriginalSettings] = useState({});
  const [saveStatus, setSaveStatus] = useState('');

  useEffect(() => {
    fetchAllSettings();
    if (userId) {
      fetchUserSettings(userId);
    }
  }, [userId]);

  const fetchAllSettings = async () => {
    try {
      const settings = await getAllSettings();
      setAllSettings(settings);
    } catch (error) {
      console.error('Failed to fetch all settings:', error);
    }
  };

  const fetchUserSettings = async (userId) => {
    try {
      const settings = await getUserSettingsById(userId);
      setUserSettings(settings);
      setOriginalSettings(settings);  // Save the original settings
      setDirtySettings({});  // Clear any dirty settings
    } catch (error) {
      console.error('Failed to fetch user settings:', error);
    }
  };

  const handleToggleSetting = (settingName, currentValue) => {
    const newValue = !currentValue;
    setUserSettings(prevSettings => ({
      ...prevSettings,
      [settingName]: newValue
    }));
    setDirtySettings(prevDirtySettings => ({
      ...prevDirtySettings,
      [settingName]: newValue
    }));
  };

  const handleTextChange = (settingName, newValue) => {
    setUserSettings(prevSettings => ({
      ...prevSettings,
      [settingName]: newValue
    }));
    setDirtySettings(prevDirtySettings => ({
      ...prevDirtySettings,
      [settingName]: newValue
    }));
  };

  const handleSave = async () => {
    try {
      for (const [settingName, value] of Object.entries(dirtySettings)) {
        await setUserSetting(userId, settingName, value);
      }
      setSaveStatus('Settings saved successfully.');
      fetchUserSettings(userId);  // Refresh settings after saving
      setDirtySettings({});  // Clear dirty settings
    } catch (error) {
      setSaveStatus('Failed to save settings: ' + error.message);
    }
  };

  const handleCancel = () => {
    setUserSettings(originalSettings);
    setDirtySettings({});
    setSaveStatus('');
  };

  const renderSettings = () => {
    return Object.keys(allSettings).map((settingName) => {
      const setting = allSettings[settingName];
      const userValue = userSettings[settingName] !== undefined ? userSettings[settingName] : setting.default;

      if (setting.type === 'boolean') {
        const isChecked = userValue === true || userValue === "true";
        return (
          <ListItem key={settingName}>
            <ListItemText primary={settingName.replace(/_/g, ' ')} />
            <FormControlLabel
              control={
                <Switch
                  checked={isChecked}  // Ensure this is a boolean
                  onChange={() => handleToggleSetting(settingName, isChecked)}
                  name={settingName}
                  color="primary"
                />
              }
              label={isChecked ? 'On' : 'Off'}
            />
          </ListItem>
        );
      } else {
        return (
          <ListItem key={settingName}>
            <ListItemText primary={settingName.replace(/_/g, ' ')} />
            <TextField
              value={userValue}
              onChange={(e) => handleTextChange(settingName, e.target.value)}
              name={settingName}
              fullWidth
            />
          </ListItem>
        );
      }
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>User Settings</Typography>
      <List>
        {renderSettings()}
      </List>
      {Object.keys(dirtySettings).length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSave} sx={{ mr: 2 }}>
            Save
          </Button>
          <Button variant="contained" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      )}
      {saveStatus && (
        <Typography sx={{ mt: 2, color: saveStatus.includes('successfully') ? 'green' : 'red' }}>
          {saveStatus}
        </Typography>
      )}
    </Box>
  );
};

export default UserSettingsManager;
