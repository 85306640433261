// Relative Path: src/components/BookRequestManager/BookRequest.js

import React, { useRef, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AssistantThread from '../AssistantThread/AssistantThread';
import BookReader from './BookReader/BookReader';
import BookRequestStatusBar from './BookRequestStatusBar';

const BookRequest = ({
  headerHeight,
  statusBarHeight,
  setStatusBarHeight,
  isLoading,
  isChatVisible,
  requestInfo,
  book,
  handleFetchRequest,
  handleStart,
  handleClose,
  handleUpdateReadingPointer,
  getStatusText,
  isStartButtonVisible,
  setCurrentIndices,
  currentIndices
}) => {
  const containerRef = useRef(null);
  const statusBarRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (statusBarRef.current && setStatusBarHeight) {
        const height = statusBarRef.current.clientHeight;
        setStatusBarHeight(height);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => window.removeEventListener('resize', updateHeight);
  }, [setStatusBarHeight]);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        height: `calc(100vh - ${headerHeight}px - ${statusBarHeight}px)`,
        overflowY: 'auto',
      }}
    >
      {requestInfo && (
        <AssistantThread
          threadId={requestInfo.openaiThreadId}
          isVisible={isChatVisible}
          onResponse={handleFetchRequest}
          headerHeight={headerHeight}
          chatHeight={`calc(100vh - ${headerHeight}px - 30px)`}
          chatWidth={{ xs: '100%', sm: 600 }}
          disableClose={!requestInfo.book}
        />
      )}
      {book && (
        <BookReader
          book={book}
          coverPhotoLink={book.coverImagePath}
          isLoading={isLoading}
          onLoad={handleStart}
          onUpdateReadingPointer={handleUpdateReadingPointer}
          containerRef={containerRef}
          setCurrentIndices={setCurrentIndices}
          currentIndices={currentIndices}
        />
      )}
      <IconButton
        onClick={handleClose}
        sx={{ position: 'fixed', top: headerHeight, right: 0, zIndex: 1300 }}
        color="secondary"
      >
        <CloseIcon />
      </IconButton>
      <BookRequestStatusBar
        requestInfo={requestInfo}
        onStart={handleStart}
        onHeightChange={setStatusBarHeight}
        getStatusText={getStatusText}
        isStartButtonVisible={isStartButtonVisible}
      />
    </Box>
  );
};

export default BookRequest;
