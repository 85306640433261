// zarabyte/zarabyte-react/src/components/guestPages/FeaturesPage/FeaturesPage.js
import React from 'react';
import { Typography, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';
import personalizedIcon from '../../../assets/personalized-icon.webp';
import dynamicContentIcon from '../../../assets/dynamic-content-icon.webp';
import aiAssistedIcon from '../../../assets/ai-assisted-icon.webp';

const features = [
  {
    title: "Personalized Content",
    description: "Zarabyte allows you to define your preferences, resulting in content that is uniquely tailored to your interests. Whether it's the genre, style, or subject matter, every book you create is a reflection of your personal tastes.",
    icon: personalizedIcon
  },
  {
    title: "Dynamic Updates",
    description: "The content you read is not static. As you progress, Zarabyte updates and evolves the material, keeping your reading experience fresh and aligned with your journey. This dynamic approach ensures continuous engagement.",
    icon: dynamicContentIcon
  },
  {
    title: "AI Assistance",
    description: "Utilizing the latest in AI technology, Zarabyte provides assistance in generating and managing content. Our AI ensures that the content adapts to your preferences, offering suggestions and creating a seamless reading experience.",
    icon: aiAssistedIcon
  }
];

const FeaturesPage = () => (
  <Box 
    sx={{ padding: '40px 20px', textAlign: 'center', backgroundColor: '#f9f9f9' }} 
    role="main" 
    aria-labelledby="features-heading"
  >
    <Typography 
      variant="h3" 
      gutterBottom 
      sx={{ fontWeight: 'bold', marginBottom: '20px' }} 
      component="h1" 
      id="features-heading"
    >
      Features
    </Typography>
    <Grid container spacing={4} justifyContent="center">
      {features.map((feature) => (
        <Grid item xs={12} sm={6} md={4} key={feature.title}>
          <Card 
            sx={{ maxWidth: 345, mx: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }} 
            role="region" 
            aria-labelledby={`${feature.title.toLowerCase().replace(/\s+/g, '-')}-heading`}
          >
            <CardMedia
              component="img"
              height="140"
              image={feature.icon}
              alt={feature.title}
              sx={{ width: 'auto', margin: 'auto' }}
            />
            <CardContent>
              <Typography 
                gutterBottom 
                variant="h5" 
                component="div" 
                sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                id={`${feature.title.toLowerCase().replace(/\s+/g, '-')}-heading`}
              >
                {feature.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1rem' }}>
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    <Box 
      sx={{ marginTop: '40px', textAlign: 'left', mx: 'auto', maxWidth: '900px' }} 
      role="region" 
      aria-labelledby="additional-features-heading"
    >
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ fontWeight: 'bold' }} 
        id="additional-features-heading"
      >
        Additional Features
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Hierarchical Bookmarks:</strong> Easily navigate through different sections and chapters with detailed hierarchical bookmarks. This allows for quick access to any part of the book and enhances your ability to organize your reading experience.
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Continuous Content Generation:</strong> As you near the end of the current material, Zarabyte generates additional content to keep you engaged. This feature ensures that you always have new content to explore, maintaining the continuity of your reading journey.
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Interactive Personal Assistant:</strong> Engage with your personal assistant to provide feedback, request new content, or get recommendations on how to continue your book. This interaction helps tailor the reading experience to your evolving preferences.
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Audiobook Support (Upcoming):</strong> We are working on expanding our platform to support audiobooks, providing an auditory reading experience for users who prefer listening to their books.
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Personalized Settings:</strong> Adjust the reading experience to your liking with customizable settings such as font size, paging, or scrolling options. This ensures that your interaction with the content is as comfortable and enjoyable as possible.
      </Typography>
    </Box>
  </Box>
);

export default FeaturesPage;
