// src/components/TechnologyPage/TechnologyPage.js
import React from 'react';
import { Typography, Box, Grid, Card, CardContent, CardMedia, List, ListItem, ListItemText } from '@mui/material';
import technologyImage from '../../../assets/technology-image.webp';

const TechnologyPage = () => (
  <Box 
    sx={{ padding: '40px 20px', textAlign: 'center', backgroundColor: '#f9f9f9' }} 
    role="main" 
    aria-labelledby="technology-page-heading"
  >
    <Typography 
      variant="h3" 
      gutterBottom 
      sx={{ fontWeight: 'bold', marginBottom: '20px' }} 
      id="technology-page-heading" 
      component="h1"
    >
      Our Technology
    </Typography>
    <Grid container spacing={4} justifyContent="center">
      {/* Detailed Technology Description */}
      <Grid item xs={12} md={6}>
        <Card 
          sx={{ mx: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '20px' }} 
          role="region" 
          aria-labelledby="core-technology-heading"
        >
          <CardContent>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'bold' }} 
              id="core-technology-heading" 
              component="h2"
            >
              Core Technology
            </Typography>
            <Typography variant="body1" paragraph aria-labelledby="core-technology-heading">
              Zarabyte harnesses the power of advanced language models from OpenAI, specifically GPT-4, to craft dynamic and personalized content. This allows us to deliver a reading experience that evolves in real-time based on the reader's journey.
            </Typography>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'bold', marginTop: '20px' }} 
              id="hierarchical-content-heading" 
              component="h2"
            >
              Hierarchical Content Structure
            </Typography>
            <Typography variant="body1" paragraph aria-labelledby="hierarchical-content-heading">
              Our platform employs a hierarchical content structure to organize and present dynamically generated material. This method supports seamless content creation, ensuring that readers consistently have fresh, relevant material at their fingertips.
            </Typography>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'bold', marginTop: '20px' }} 
              id="structured-processes-heading" 
              component="h2"
            >
              Structured Language Model Processes
            </Typography>
            <Typography variant="body1" paragraph aria-labelledby="structured-processes-heading">
              Using sophisticated algorithms, we analyze user preferences and reading habits. This enables the generation of content that is not only personalized but also adaptive, continually evolving to meet the unique needs of each reader.
            </Typography>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'bold', marginTop: '20px' }} 
              id="future-enhancements-heading" 
              component="h2"
            >
              Future Enhancements
            </Typography>
            <Typography variant="body1" paragraph aria-labelledby="future-enhancements-heading">
              As we advance our platform, we are committed to refining our technology to enhance quality and reduce costs. Future developments include expanding support for audiobooks and improving the personal assistant’s capabilities to offer even more customized reading experiences.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {/* Key Features List */}
      <Grid item xs={12} md={6}>
        <Card 
          sx={{ mx: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }} 
          role="region" 
          aria-labelledby="key-features-heading"
        >
          <CardMedia
            component="img"
            height="240"
            image={technologyImage}
            alt="Our Technology"
            sx={{ width: 'auto', height: '240px', borderRadius: '8px', margin: 'auto', marginTop: '20px' }}
          />
          <CardContent>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'bold' }} 
              id="key-features-heading" 
              component="h2"
            >
              Key Features of Our Technology
            </Typography>
            <List>
              <ListItem>
                <ListItemText 
                  primary="Advanced Language Models" 
                  secondary="Utilizing GPT-4 for dynamic content generation." 
                  aria-labelledby="key-features-heading"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Hierarchical Content Structure" 
                  secondary="Organizing content in a way that supports continuous reading." 
                  aria-labelledby="key-features-heading"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Personalized and Adaptive Content" 
                  secondary="Generating content that evolves with user preferences and habits." 
                  aria-labelledby="key-features-heading"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Future-ready Enhancements" 
                  secondary="Expanding to support audiobooks and more personalized features." 
                  aria-labelledby="key-features-heading"
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Box>
);

export default TechnologyPage;
