import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import heroImage from '../../../../assets/hero-image.webp';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'center',
        height: '100vh',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark overlay for text contrast
        padding: '0 20px',
      }}
      role="region"
      aria-labelledby="hero-section-title"
    >
      {/* Background Image */}
      <Box
        component="img"
        src={heroImage}
        alt="Hero Background"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          transform: 'translate(-50%, -50%)', // Center the image
          zIndex: -2, // Ensure it's behind content
          opacity: 0.5, // Add a subtle opacity
        }}
      />

      {/* Content Overlay */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          maxWidth: '800px',
          textAlign: 'center',
          textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
          animation: 'fadeIn 2s', // MUI animation
        }}
      >
        <Typography
          id="hero-section-title"
          variant="h2"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2.5rem', md: '4rem' },
            '@keyframes fadeIn': {
              '0%': { opacity: 0 },
              '100%': { opacity: 1 },
            },
            animation: 'fadeIn 1s',
          }}
        >
          Discover Your Next Adventure with AI-Powered Books
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            marginBottom: '30px',
            fontSize: { xs: '1.25rem', md: '1.75rem' },
            '@keyframes fadeIn': {
              '0%': { opacity: 0 },
              '100%': { opacity: 1 },
            },
            animation: 'fadeIn 1.5s',
          }}
        >
          Tailored Reading Experiences That Evolve with You
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/contact')}
          sx={{
            padding: '12px 24px',
            fontSize: { xs: '16px', md: '20px' },
            '@keyframes fadeIn': {
              '0%': { opacity: 0 },
              '100%': { opacity: 1 },
            },
            animation: 'fadeIn 2.5s',
            margin: '0 10px',
          }}
          aria-label="Contact us"
        >
          Contact Us
        </Button>
      </Box>
    </Box>
  );
};

export default HeroSection;
