//zarabyte/zarabyte-react/src/components/Header/UserSection.js

import React from 'react';
import { Box, Button, Typography, Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

const UserSection = ({ userDetails, handleLogoutClick }) => {
  const navigate = useNavigate();
  
  return (
    userDetails ? (
      <Box display="flex" alignItems="center">
        <Avatar sx={{ bgcolor: 'secondary.main', mr: 1 }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography variant="subtitle1" component="span" sx={{ mr: 2 }}>
          {userDetails.name}
        </Typography>
        <Button color="inherit" onClick={handleLogoutClick}>Sign Out</Button>
      </Box>
    ) : (
      <Button color="inherit" onClick={() => navigate('/signin')}>Sign In</Button>
    )
  );
};

export default UserSection;