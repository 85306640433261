//zarabyte/zarabyte-react/src/components/guestPages/SignIn/SignIn.js

import React from 'react';
import AuthHandler from '../../auth/AuthHandler';

const SignIn = () => {
  return <AuthHandler initialState="signIn" />;
};

export default SignIn;
