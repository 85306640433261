import { request } from './request';

const assistantsApi = {
  /**
   * Fetches Messages. see https://platform.openai.com/docs/api-reference/messages/listMessages
   * @param {MessageListParams} query 
   * @returns {Promise<Messages>} The fetched messages.
   */
  getMessages: async (threadId, query) => {
    try {
      const queryParams = new URLSearchParams(query).toString();
      const response = await request('GET', `/openai-assistant-thread/${threadId}/messages?${queryParams}`);
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch Messages:', error);
      throw error;
    }
  },


  /**
   * Post user message.
   * @param {string} content - the message content 
   * @returns {Promise<Message>} - the message object that was added to thread
   */
  postMessage: async (threadId, content) => {
    try {
      const response = await request('POST', `/openai-assistant-thread/${threadId}/message`, {content});
      return await response.json();
    } catch (error) {
      console.error('Failed to post user message:', error);
      throw error;
    }
  },

  /**
   * Fetches Messages. see https://platform.openai.com/docs/api-reference/messages/listMessages
   * @returns {Promise<Messages>} The fetched messages.
   */
  getAssistantResponse: async (threadId) => {
    try {
      const response = await request('GET', `/openai-assistant-thread/${threadId}/assistant`);
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch Messages:', error);
      throw error;
    }
  },
};

export default assistantsApi;