// zarabyte/zarabyte-react/src/components/ContactUs/ContactUs.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createUnauthenticatedContact, createAuthenticatedContact } from '../../backend/userContact';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userDetails);
  const [message, setMessage] = useState('');
  const [statedName, setStatedName] = useState('');
  const [statedEmail, setStatedEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (user) {
        await createAuthenticatedContact(message);
        
        navigate('/books'); 
        toast.success('Message sent successfully!', {});
      } else {
        await createUnauthenticatedContact(statedName, statedEmail, message);
        navigate('/');
        toast.success('Message sent successfully!', {});
      }
    } catch (error) {
      console.error('Error submitting contact:', error);
      toast.error('Failed to send message. Please try again.', {});
    }
  };

  return (
    <Container>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions, feel free to reach out to us.
        </Typography>
        <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
          {!user && (
            <>
              <TextField
                fullWidth
                label="Your Name"
                variant="outlined"
                margin="normal"
                value={statedName}
                onChange={(e) => setStatedName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Your Email"
                variant="outlined"
                margin="normal"
                value={statedEmail}
                onChange={(e) => setStatedEmail(e.target.value)}
              />
            </>
          )}
          <TextField
            fullWidth
            label="Your Message"
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Send Message
          </Button>
        </Box>
        <ToastContainer />
      </Box>
    </Container>
  );
};

export default ContactUs;
