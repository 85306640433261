// src/components/ProductPage/ProductPage.js
import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import productImage from '../../../assets/product-image.webp';

const ProductPage = () => (
  <Box 
    sx={{ padding: '40px 20px', textAlign: 'center', backgroundColor: '#f9f9f9' }} 
    role="main" 
    aria-labelledby="product-page-heading"
  >
    <Typography 
      variant="h3" 
      gutterBottom 
      sx={{ fontWeight: 'bold', marginBottom: '20px' }} 
      id="product-page-heading" 
      component="h1"
    >
      Zarabyte Books
    </Typography>
    
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} md={10}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box component="section" sx={{ textAlign: 'left', padding: '0 20px' }}>
              <Typography 
                variant="body1" 
                paragraph 
                sx={{ fontSize: '1.1rem', fontWeight: 'bold' }} 
                component="h2" 
                id="personalized-reading-heading"
              >
                Personalized Reading Experience
              </Typography>
              <Typography 
                variant="body1" 
                paragraph 
                sx={{ fontSize: '1rem' }} 
                aria-labelledby="personalized-reading-heading"
              >
                At Zarabyte Books, we believe that your reading journey should be as unique as you are. Our platform allows you to create personalized books by defining your preferences. With the help of AI, we generate content that evolves with your interests and reading habits, providing a truly tailored experience.
              </Typography>
              <Typography 
                variant="body1" 
                paragraph 
                sx={{ fontSize: '1.1rem', fontWeight: 'bold' }} 
                component="h2" 
                id="dynamic-content-heading"
              >
                Dynamic Content Generation
              </Typography>
              <Typography 
                variant="body1" 
                paragraph 
                sx={{ fontSize: '1rem' }} 
                aria-labelledby="dynamic-content-heading"
              >
                The platform uses advanced AI to dynamically generate content that adapts as you read. This ensures that your book continuously grows, keeping you engaged and making each reading session refreshing and relevant.
              </Typography>
              <Typography 
                variant="body1" 
                paragraph 
                sx={{ fontSize: '1.1rem', fontWeight: 'bold' }} 
                component="h2" 
                id="seamless-navigation-heading"
              >
                Seamless Navigation
              </Typography>
              <Typography 
                variant="body1" 
                paragraph 
                sx={{ fontSize: '1rem' }} 
                aria-labelledby="seamless-navigation-heading"
              >
                With a continuous scroll format and hierarchical bookmarks, you can easily navigate through different sections and chapters of your book. Click on any chapter to jump directly to that part of the story, and view additional information about each section.
              </Typography>
              <Typography 
                variant="body1" 
                paragraph 
                sx={{ fontSize: '1.1rem', fontWeight: 'bold' }} 
                component="h2" 
                id="interactive-assistant-heading"
              >
                Interactive Personal Assistant
              </Typography>
              <Typography 
                variant="body1" 
                paragraph 
                sx={{ fontSize: '1rem' }} 
                aria-labelledby="interactive-assistant-heading"
              >
                As you approach the end of the current content, our system generates more material to keep you engaged. You can chat with your personal assistant to provide feedback and guide the direction of your book, ensuring it aligns with your evolving preferences.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={productImage}
              alt="Zarabyte Books"
              sx={{ width: '100%', height: 'auto', borderRadius: '8px', marginBottom: '20px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default ProductPage;
