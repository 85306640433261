//zarabyte/zarabyte-react/src/components/guestPages/SignUp/SignUp.js

import React from 'react';
import AuthHandler from '../../auth/AuthHandler';

const SignUp = () => {
  return <AuthHandler initialState="signUp" />;
};

export default SignUp;
