// path: src/components/BookRequestsManager/BookRequestsManager.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BookRequestsList from '../BookRequestsList/BookRequestsList';
import bookWriterManagerAPI from '../../backend/bookWriterManager';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography, Container, CircularProgress, Alert } from '@mui/material';
import { checkCreditBalance } from '../../backend/credit';

const BookRequestsManager = ({ isArchive }) => {
  const [bookRequests, setBookRequests] = useState([]);
  const [userCredits, setUserCredits] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user.userDetails);

  const fetchBookRequests = useCallback(async () => {
    try {
      const data = await bookWriterManagerAPI.getBookRequestsInfo(isArchive);
      setBookRequests(data);
    } catch (error) {
      console.error('Failed to fetch book requests:', error);
    }
  }, [isArchive]);

  const fetchUserCredits = useCallback(async () => {
    try {
      const credits = await checkCreditBalance();
      setUserCredits(credits);
    } catch (error) {
      console.error('Failed to fetch user credits:', error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchBookRequests();
      await fetchUserCredits();
      setLoading(false);
    };
    fetchData();
  }, [fetchBookRequests, fetchUserCredits]);

  const handleNewBookRequest = async () => {
    try {
      const req = await bookWriterManagerAPI.requestBook();
      navigate(`/books/${req._id}`);
    } catch (error) {
      console.error('Failed to create new book request:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (userDetails && userDetails.journey && bookRequests.length == 0) {
    const { status } = userDetails.journey;

    if (status === 'new') {
      return (
        <Container>
          <Box sx={{ textAlign: 'center', marginTop: '20%' }}>
            <Typography variant="h4" gutterBottom>
              Welcome to Zarabyte!
            </Typography>
            <Typography variant="body1" gutterBottom>
              We’re thrilled to have you here! As a beta user, you’re on the verge of an exciting journey into the future of personalized reading. 
              To get started and receive your beta credits, please contact us. Together, let’s revolutionize the way you read!
            </Typography>
            <Button variant="contained" color="primary" onClick={() => navigate('/contact')}>
              Contact Us to Become a Beta Tester
            </Button>
          </Box>
        </Container>
      );
    }

    if (status === 'allocated' || status === 'active-user') {
      return (
        <Container>
          <Box sx={{ textAlign: 'center', marginTop: '20%' }}>
            <Typography variant="h4" gutterBottom>
              Welcome to Zarabyte!
            </Typography>
            <Typography variant="body1" gutterBottom>
              We’re excited to have you as a beta tester! With your allocated credits, you can now dive into creating your personalized books. 
              Explore our innovative platform and help us shape the future of reading with your valuable feedback!
            </Typography>
            <Button variant="contained" color="primary" onClick={handleNewBookRequest}>
              Create Your First Book
            </Button>
          </Box>
        </Container>
      );
    }
  }

  return (
    <Container>
      {userCredits !== undefined && userCredits < 500 && (
        <Box sx={{ mb: 2 }}>
          <Alert severity="warning">Your credits are running low. Please contact support to add more credits.</Alert>
        </Box>
      )}
      <BookRequestsList
        bookRequests={bookRequests}
        selectBookRequest={(requestId) => navigate(`/books/${requestId}`)}
        workOnBookRequest={(requestId) => bookWriterManagerAPI.workOnBookRequest(requestId).then(fetchBookRequests)}
        stopWorkingOnBookRequest={(requestId) => bookWriterManagerAPI.stopWorkingOnBookRequest(requestId).then(fetchBookRequests)}
        archiveBookRequest={(requestId) => bookWriterManagerAPI.archiveRequest(requestId).then(fetchBookRequests)}
        isArchive={isArchive}
      />
      {!isArchive && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          onClick={handleNewBookRequest}
        >
          <AddIcon />
        </Fab>
      )}
    </Container>
  );
};

export default BookRequestsManager;
