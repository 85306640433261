import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { updateContactStatus, updateSupportComments } from '../../backend/userContact';

const statusOptions = ['new', 'pending', 'in-progress', 'rejected', 'handled', 'follow'];

const ContactDetails = ({ contact, journey, onContactUpdate, onGoToJourney }) => {
  const [newStatus, setNewStatus] = useState(contact.status);
  const [newComment, setNewComment] = useState(contact.supportComments);

  const handleUpdateStatus = async () => {
    try {
      await updateContactStatus(contact._id, newStatus);
      alert('Contact status updated successfully');
      onContactUpdate();
    } catch (error) {
      alert('Failed to update contact status: ' + error.message);
    }
  };

  const handleUpdateComment = async () => {
    try {
      await updateSupportComments(contact._id, newComment);
      alert('Support comments updated successfully');
      onContactUpdate();
    } catch (error) {
      alert('Failed to update support comments: ' + error.message);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Contact Details</Typography>
      <Typography variant="body1"><strong>User ID:</strong> {journey?.userId || 'N/A'}</Typography>
      <Typography variant="body1"><strong>User Name:</strong> {journey?.name || 'N/A'}</Typography>
      <Typography variant="body1"><strong>User Email:</strong> {journey?.email || 'N/A'}</Typography>
      <Typography variant="body1"><strong>Message:</strong> {contact.message}</Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel>Status</InputLabel>
        <Select
          value={newStatus}
          onChange={(e) => setNewStatus(e.target.value)}
          label="Status"
        >
          {statusOptions.map((status) => (
            <MenuItem key={status} value={status}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Comments"
        fullWidth
        margin="normal"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
      />

      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleUpdateStatus} sx={{ mr: 2 }}>
          Update Status
        </Button>
        <Button variant="contained" color="secondary" onClick={handleUpdateComment} sx={{ mr: 2 }}>
          Update Comments
        </Button>
        <Button variant="contained" onClick={onGoToJourney}>
          Go to Journey
        </Button>
      </Box>
    </Box>
  );
};

export default ContactDetails;
