// Relative Path: src/components/Assistant/ChatMessage.js
import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

const ChatMessage = ({ message }) => {
  const isUser = message.role === 'user';

  const renderers = {
    code: ({ node, inline, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={materialLight}
          language={match[1]}
          children={String(children).replace(/\n$/, '')}
          {...props}
        />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  return (
    <Paper
      elevation={3}
      sx={{
        my: 1,
        p: 2,
        bgcolor: isUser ? 'primary.light' : 'secondary.light',
        maxWidth: '600px',
        alignSelf: isUser ? 'flex-end' : 'flex-start',
        borderRadius: '10px', // Rounded corners
        mx: 1, // Small margin on the sides
      }}
    >
      <Typography
        variant="caption"
        sx={{ color: isUser ? 'primary.contrastText' : 'secondary.contrastText', fontSize: '10px' }}
      >
        {isUser ? 'User' : 'Assistant'}
      </Typography>
      {message.content.map((item, index) => (
        <Box key={index} sx={{ mt: 1 }}>
          {item.type === 'text' ? (
            <ReactMarkdown children={item.text.value} components={renderers} />
          ) : (
            <Box
              component="img"
              sx={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: 2,
              }}
              src={URL.createObjectURL(item.image_file.file)}
              alt="Chat content"
            />
          )}
        </Box>
      ))}
    </Paper>
  );
};

export default ChatMessage;
