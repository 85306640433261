// src/components/Header/CreditBalance.js

import React, { useState, useEffect } from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { checkCreditBalance } from '../../backend/credit';

const CreditBalance = () => {
  const [balanceAnchorEl, setBalanceAnchorEl] = useState(null);
  const [balance, setBalance] = useState('');
  const [balancePopoverOpen, setBalancePopoverOpen] = useState(false);

  useEffect(() => {
    const fetchAndShowBalance = async () => {
      if (balancePopoverOpen) {
        try {
          const fetchedBalance = await checkCreditBalance();
          setBalance(fetchedBalance);
        } catch (error) {
          console.error('Error fetching balance:', error);
          setBalance('Failed to fetch balance');
        }
      }
    };

    fetchAndShowBalance();
  }, [balancePopoverOpen]);

  const handleCreditClick = (event) => {
    setBalanceAnchorEl(event.currentTarget);
    setBalancePopoverOpen(true);
  };

  const handleClose = () => {
    setBalanceAnchorEl(null);
    setBalancePopoverOpen(false);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleCreditClick}>
        <CreditCardIcon />
      </IconButton>
      <Popover
        id={balancePopoverOpen ? 'balance-popover' : undefined}
        open={balancePopoverOpen}
        anchorEl={balanceAnchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Typography sx={{ p: 2 }}>
          Balance: {balance !== '' ? parseFloat(balance).toFixed(2) : 'Fetching...'}
        </Typography>
      </Popover>
    </>
  );
};

export default CreditBalance;
