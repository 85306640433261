import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText } from '@mui/material';
import { allocateCredits, emailInviteForBeta, deleteJourney } from '../../backend/userJourney';
import { creditAccount, debitAccount, adminCheckCreditBalance } from '../../backend/credit';
import UserSettingsManager from './UserSettingsManager';

const UserJourneyDetails = ({ journey, onJourneyUpdate, onBack }) => {
  const [newCredits, setNewCredits] = useState(0);
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [balanceMessage, setBalanceMessage] = useState('');
  const [inviteSent, setInviteSent] = useState(false);

  useEffect(() => {
    if (journey) {
      setNewCredits(0);
      setAmount('');
      setDescription('');
      setBalanceMessage('');
      setInviteSent(journey.steps.some(step => step.stepName === 'Email: Invite for beta'));
    }
  }, [journey]);

  const handleAllocateCredits = async () => {
    try {
      await allocateCredits(journey.email, newCredits);
      alert('Credits allocated successfully');
      onJourneyUpdate();
    } catch (error) {
      alert('Failed to allocate credits: ' + error.message);
    }
  };

  const handleSendInvite = async () => {
    try {
      await emailInviteForBeta(journey.name, journey.email);
      alert('Invite email sent successfully');
      onJourneyUpdate();
    } catch (error) {
      alert('Failed to send invite email: ' + error.message);
    }
  };

  const handleDeleteJourney = async () => {
    if (window.confirm('Are you sure you want to delete this journey? This action cannot be undone.')) {
      try {
        await deleteJourney(journey._id);
        alert('Journey deleted successfully');
        onJourneyUpdate();
      } catch (error) {
        alert('Failed to delete journey: ' + error.message);
      }
    }
  };

  const handleCredit = async () => {
    try {
      await creditAccount(journey.userId, amount, description);
      alert('Account credited successfully');
    } catch (error) {
      alert('Failed to credit account: ' + error.message);
    }
  };

  const handleDebit = async () => {
    try {
      await debitAccount(journey.userId, amount, description);
      alert('Account debited successfully');
    } catch (error) {
      alert('Failed to debit account: ' + error.message);
    }
  };

  const checkBalance = async () => {
    try {
      const balance = await adminCheckCreditBalance(journey.userId);
      setBalanceMessage(`Current Balance for User ID ${journey.userId}: ${balance}`);
    } catch (error) {
      setBalanceMessage('Failed to fetch balance: ' + error.message);
    }
  };

  if (!journey) {
    return null;
  }

  return (
    <Box>
      <Button variant="contained" onClick={onBack} sx={{ mb: 2 }}>Back to List</Button>
      <Typography variant="h6">Journey Details</Typography>
      <Typography variant="body1"><strong>Id:</strong> {journey._id}</Typography>
      <Typography variant="body1"><strong>Email:</strong> {journey.email}</Typography>
      <Typography variant="body1"><strong>Name:</strong> {journey.name}</Typography>
      <Typography variant="body1"><strong>Journey Type:</strong> {journey.journeyType}</Typography>
      <Typography variant="body1"><strong>Status:</strong> {journey.status}</Typography>
      <Typography variant="body1"><strong>Allocated Credits:</strong> {journey.allocatedCredits}</Typography>
      {journey.userId && <Typography variant="body1"><strong>User ID:</strong> {journey.userId}</Typography>}

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Journey Steps</Typography>
      {journey.steps && journey.steps.length > 0 ? (
        <List>
          {journey.steps.map((step, index) => (
            <ListItem key={index}>
              <ListItemText primary={step.stepName} secondary={new Date(step.timestamp).toLocaleString()} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">No steps available for this journey.</Typography>
      )}

      {journey.userId && (
        <Box>
          <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Credit/Debit Account</Typography>
          <TextField
            label="Amount"
            type="number"
            fullWidth
            margin="normal"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <TextField
            label="Description"
            fullWidth
            margin="normal"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleCredit} sx={{ mr: 2 }}>
              Credit Account
            </Button>
            <Button variant="contained" color="secondary" onClick={handleDebit} sx={{ mr: 2 }}>
              Debit Account
            </Button>
            <Button variant="outlined" onClick={checkBalance}>Check Balance</Button>
          </Box>
          {balanceMessage && <Typography sx={{ mt: 2 }}>{balanceMessage}</Typography>}

          <Box sx={{ mt: 4 }}>
            <UserSettingsManager userId={journey.userId} />
          </Box>
        </Box>
      )}

      <Box>
        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Allocate Credits</Typography>
        <TextField
          label="Credits"
          type="number"
          fullWidth
          margin="normal"
          value={newCredits}
          onChange={(e) => setNewCredits(parseInt(e.target.value))}
        />
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleAllocateCredits} sx={{ mr: 2 }}>
            Allocate Credits
          </Button>
          {!inviteSent && (
            <Button variant="contained" color="secondary" onClick={handleSendInvite} sx={{ mr: 2 }}>
              Send Invite Email
            </Button>
          )}
          {!journey.userId && (
            <Button variant="outlined" color="error" onClick={handleDeleteJourney}>
              Delete Journey
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UserJourneyDetails;
