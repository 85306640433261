import React, { useState } from 'react';
import { ListItemText, IconButton, Collapse, Tooltip, List, ListItemButton, Box, useMediaQuery } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BookMarkChapterItem from './BookMarkChapterItem';
import { useTheme } from '@mui/material/styles';

const BookMarkPartItem = ({ part, partIndex, currentPartIndex, currentChapterIndex, selectChapter, hasMultipleParts, handleInfoClick }) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasChapters = part.chapters && part.chapters.length > 0;
  const isSelected = partIndex === currentPartIndex;
  const [showTooltip, setShowTooltip] = useState(false);

  const handlePartClick = () => {
    setExpanded(!expanded);
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setShowTooltip(false);
    }
  };

  return (
    <>
      {hasMultipleParts && (
        <Box
          display="flex"
          alignItems="center"
          sx={{ backgroundColor: isSelected ? '#b2ebf2' : 'transparent' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ListItemButton onClick={handlePartClick} disabled={!hasChapters} sx={{ flexGrow: 1 }}>
            <ListItemText
              primary={part.title}
              primaryTypographyProps={{ variant: 'h6', color: partIndex === currentPartIndex ? 'textPrimary' : 'textSecondary' }}
            />
            {hasChapters && (expanded ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          {!isMobile && showTooltip && (
            <Tooltip title={part.description} arrow>
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
          {isMobile && (
            <IconButton size="small" onClick={() => handleInfoClick(part.description)}>
              <InfoIcon />
            </IconButton>
          )}
        </Box>
      )}
      <Collapse in={expanded || !hasMultipleParts} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {part.chapters?.map((chapter, chapterIndex) => (
            <BookMarkChapterItem
              key={chapter.title}
              chapter={chapter}
              chapterIndex={chapterIndex}
              currentPartIndex={currentPartIndex}
              currentChapterIndex={currentChapterIndex}
              selectChapter={selectChapter}
              partIndex={partIndex}
              handleInfoClick={handleInfoClick}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default BookMarkPartItem;
