// zarabyte/zarabyte-react/src/components/guestPages/PrivacyPolicy/PrivacyPolicy.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const PrivacyPolicy = () => (
  <main role="main" aria-labelledby="privacy-policy-title">
    <Box sx={{ padding: '40px 20px', textAlign: 'left', backgroundColor: '#f9f9f9', maxWidth: '900px', margin: '0 auto' }}>
      <Typography 
        id="privacy-policy-title" 
        variant="h3" 
        gutterBottom 
        sx={{ fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }} 
        component="h1"
      >
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Effective Date: 1/7/2024
      </Typography>
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ fontWeight: 'bold', marginTop: '20px' }} 
        component="h2"
      >
        Introduction
      </Typography>
      <Typography variant="body1" paragraph>
        Zarabyte ("we", "our", "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website https://www.zarabyte.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
      </Typography>

      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ fontWeight: 'bold', marginTop: '20px' }} 
        component="h2"
      >
        Collection of Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We may collect information about you in a variety of ways. The information we may collect on the Site includes:
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Personal Data</strong><br />
        Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Derivative Data</strong><br />
        Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Financial Data</strong><br />
        Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. [We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, [Insert Payment Processor], and you are encouraged to review their privacy policy and contact them directly for responses to your questions.]
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Mobile Device Data</strong><br />
        Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Third-Party Data</strong><br />
        Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Site permission to access this information.
      </Typography>

      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ fontWeight: 'bold', marginTop: '20px' }} 
        component="h2"
      >
        Use of Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
      </Typography>
      <Typography variant="body1" paragraph>
        - Create and manage your account.<br />
        - Process your transactions.<br />
        - Email you regarding your account or order.<br />
        - Enable user-to-user communications.<br />
        - Generate a personal profile about you to make future visits to the Site more personalized.<br />
        - Increase the efficiency and operation of the Site.<br />
        - Monitor and analyze usage and trends to improve your experience with the Site.<br />
        - Notify you of updates to the Site.<br />
        - Offer new products, services, and/or recommendations to you.<br />
        - Perform other business activities as needed.<br />
        - Request feedback and contact you about your use of the Site.
      </Typography>

      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ fontWeight: 'bold', marginTop: '20px' }} 
        component="h2"
      >
        Disclosure of Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>By Law or to Protect Rights</strong><br />
        If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Business Transfers</strong><br />
        We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Third-Party Service Providers</strong><br />
        We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Marketing Communications</strong><br />
        With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Interactions with Other Users</strong><br />
        If you interact with other users of the Site, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, and following blogs.
      </Typography>

      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ fontWeight: 'bold', marginTop: '20px' }} 
        component="h2"
      >
        Security of Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
      </Typography>

      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ fontWeight: 'bold', marginTop: '20px' }} 
        component="h2"
      >
        Policy for Children
      </Typography>
      <Typography variant="body1" paragraph>
        We do not knowingly solicit information from or market to children under the age of 13. If we learn we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you become aware of any data we have collected from children under age 13, please contact us at [Insert Contact Email].
      </Typography>

      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ fontWeight: 'bold', marginTop: '20px' }} 
        component="h2"
      >
        Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on our site. You are advised to review this Privacy Policy periodically for any changes.
      </Typography>

      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ fontWeight: 'bold', marginTop: '20px' }} 
        component="h2"
      >
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have questions or comments about this Privacy Policy, please contact us at:
      </Typography>
      <Typography variant="body1" paragraph>
        Zarabyte<br />
        Email: support@zarabyte.com<br />
        Address: Hod Hasharon Israel
      </Typography>
    </Box>
  </main>
);

export default PrivacyPolicy;

