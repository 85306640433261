import React from 'react';
import { calculateProgress } from '../BookRequestsManager/progressCalculator';
import { ListItemText, LinearProgress, Typography, Box, IconButton, Avatar, Grid, Card, CardContent } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import BookIcon from '@mui/icons-material/Book';

const BookRequestsList = ({
  bookRequests,
  selectBookRequest,
  archiveBookRequest,
  isArchive
}) => {

  for (const req of bookRequests) {
    req.progressPercentage = calculateProgress(req.book?.progress || 0);
  }

  return (
    <Grid container spacing={2}>
      {[...bookRequests].reverse().map((request) => (
        <Grid item xs={12} sm={6} key={request._id}>
          <Card 
            onClick={() => selectBookRequest(request._id)} 
            sx={{ height: '100%', cursor: 'pointer' }}  // Added cursor pointer
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                {request.book?.title || 'New Book Request'}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={request.book?.coverImagePath || ''}
                  alt={request.book?.title || 'Book Cover'}
                  sx={{ width: 160, height: 160, marginRight: 2 }}
                  imgProps={{ style: { objectFit: 'cover' } }}
                  variant="square"
                >
                  {!request.book?.coverImagePath && <BookIcon />}
                </Avatar>
                <Box sx={{ flex: 1, minWidth: 0 }}>
                  <ListItemText
                    secondary={request.book?.description || 'No description available.'}
                    secondaryTypographyProps={{
                      variant: 'body2',
                      style: {
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                <Box sx={{ width: '30%', mr: 1 }}>
                  <LinearProgress variant="determinate" value={request.progressPercentage} />
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {`${Math.floor(request.progressPercentage)}%`}
                </Typography>
                {!isArchive && (
                  <IconButton 
                    onClick={(e) => { e.stopPropagation(); archiveBookRequest(request._id); }} size="small"
                  >
                    <ArchiveIcon />
                  </IconButton>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default BookRequestsList;
