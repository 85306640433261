import React from 'react';
import BookMarkPartItem from './BookMarkPartItem';

const BookMarkList = ({ book, currentPartIndex, currentChapterIndex, selectChapter, handleInfoClick }) => {
  const hasMultipleParts = book.parts && book.parts.length > 1;

  return (
    <>
      {book.parts?.map((part, partIndex) => (
        <BookMarkPartItem
          key={part.title}
          part={part}
          partIndex={partIndex}
          currentPartIndex={currentPartIndex}
          currentChapterIndex={currentChapterIndex}
          selectChapter={selectChapter}
          hasMultipleParts={hasMultipleParts}
          handleInfoClick={handleInfoClick}
        />
      ))}
    </>
  );
};

export default BookMarkList;
