// zarabyte/zarabyte-react/src/components/guestPages/FAQsPage/FAQsPage.js
import React from 'react';
import { Typography, Box } from '@mui/material';

const FAQsPage = () => (
  <Box 
    sx={{ padding: '40px 20px', textAlign: 'left', backgroundColor: '#f9f9f9', maxWidth: '800px', margin: '0 auto' }} 
    role="main" 
    aria-labelledby="faq-heading"
  >
    <Typography 
      variant="h3" 
      gutterBottom 
      sx={{ fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }} 
      component="h1" 
      id="faq-heading"
    >
      Frequently Asked Questions
    </Typography>
    
    <Box sx={{ marginBottom: '20px' }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ fontWeight: 'bold' }} 
        component="h2"
        id="question-1"
      >
        Q: What is Zarabyte?
      </Typography>
      <Typography variant="body1" aria-labelledby="question-1">
        A: Zarabyte is a platform that uses advanced AI to create personalized and dynamic reading experiences, adapting content to match your preferences as you read.
      </Typography>
    </Box>

    <Box sx={{ marginBottom: '20px' }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ fontWeight: 'bold' }} 
        component="h2"
        id="question-2"
      >
        Q: How does Zarabyte personalize content?
      </Typography>
      <Typography variant="body1" aria-labelledby="question-2">
        A: By analyzing your preferences and reading habits, Zarabyte generates content that evolves and adapts to keep your reading experience engaging and relevant.
      </Typography>
    </Box>

    <Box sx={{ marginBottom: '20px' }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ fontWeight: 'bold' }} 
        component="h2"
        id="question-3"
      >
        Q: How does the credit system work?
      </Typography>
      <Typography variant="body1" aria-labelledby="question-3">
        A: Zarabyte operates on a credit system based on usage of the OpenAI API. Users need to contact the admin to add credits, as there is no direct purchase option available yet.
      </Typography>
    </Box>

    <Box sx={{ marginBottom: '20px' }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ fontWeight: 'bold' }} 
        component="h2"
        id="question-4"
      >
        Q: What are the key features of Zarabyte?
      </Typography>
      <Typography variant="body1" aria-labelledby="question-4">
        A: Zarabyte offers personalized book creation, dynamic content updates, hierarchical bookmarks, and AI-assisted content management. Upcoming features include audiobook support and enhanced personal assistant capabilities.
      </Typography>
    </Box>

    <Box sx={{ marginBottom: '20px' }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ fontWeight: 'bold' }} 
        component="h2"
        id="question-5"
      >
        Q: How can I get access to Zarabyte?
      </Typography>
      <Typography variant="body1" aria-labelledby="question-5">
        A: Zarabyte is currently in beta. You can get access by contacting us through the website or via email to receive credits and start exploring the platform.
      </Typography>
    </Box>

    <Box sx={{ marginBottom: '20px' }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ fontWeight: 'bold' }} 
        component="h2"
        id="question-6"
      >
        Q: What makes Zarabyte's AI technology special?
      </Typography>
      <Typography variant="body1" aria-labelledby="question-6">
        A: Zarabyte leverages GPT-4 to dynamically generate personalized content. This integration allows for a seamless and evolving reading experience tailored to each user's journey.
      </Typography>
    </Box>

    <Box sx={{ marginBottom: '20px' }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ fontWeight: 'bold' }} 
        component="h2"
        id="question-7"
      >
        Q: What are Zarabyte's future plans?
      </Typography>
      <Typography variant="body1" aria-labelledby="question-7">
        A: We plan to expand our platform to include audiobook support, improve AI-driven content suggestions, and offer more personalized reading settings, such as adjustable font size and scrolling options.
      </Typography>
    </Box>
  </Box>
);

export default FAQsPage;
