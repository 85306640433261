import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Tabs, Tab, Box } from '@mui/material';
import ContactList from './ContactList';
import ContactDetails from './ContactDetails';
import UserJourneyList from './UserJourneyList';
import UserJourneyDetails from './UserJourneyDetails';
import { getAllContacts } from '../../backend/userContact';
import { getAllUserJourneys } from '../../backend/userJourney';

const AdminPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedContactJourney, setSelectedContactJourney] = useState(null);
  const [journeys, setJourneys] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState(null);

  useEffect(() => {
    fetchContacts();
    fetchJourneys();

  }, [tabIndex]);

  const fetchContacts = async () => {
    try {
      const fetchedContacts = await getAllContacts();
      setContacts(fetchedContacts);
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
    }
  };

  const fetchJourneys = async () => {
    try {
      const fetchedJourneys = await getAllUserJourneys();
      setJourneys(fetchedJourneys);
    } catch (error) {
      console.error('Failed to fetch journeys:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setSelectedContact(null);
    setSelectedJourney(null);
    setSelectedContactJourney(null);
  };

  const handleContactSelect = (contact) => {
    console.log(contact);
    console.log(journeys);
    const journey = journeys.find(j => j._id === contact.userJourney);
    setSelectedContact(contact);
    setSelectedContactJourney(journey);
  };

  const handleJourneySelect = (journey) => {
    setSelectedJourney(journey);
  };

  const handleJourneyUpdate = () => {
    fetchJourneys();
  };

  const handleBackToList = () => {
    setSelectedJourney(null);
  };

  const handleGoToJourney = () => {
    setTabIndex(1);
    setSelectedJourney(selectedContactJourney);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Admin Operations</Typography>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="User Contacts" />
        <Tab label="User Journeys" />
      </Tabs>
      <Box sx={{ mt: 3 }}>
        {tabIndex === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {selectedContact ? (
                <ContactDetails
                  contact={selectedContact}
                  journey={selectedContactJourney}
                  onContactUpdate={fetchContacts}
                  onGoToJourney={handleGoToJourney}
                />
              ) : (
                <Typography variant="h6">Select a contact to view details</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <ContactList contacts={contacts} onContactSelect={handleContactSelect} />
            </Grid>
          </Grid>
        )}
        {tabIndex === 1 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {selectedJourney ? (
                <UserJourneyDetails journey={selectedJourney} onJourneyUpdate={handleJourneyUpdate} onBack={handleBackToList} />
              ) : (
                <UserJourneyList journeys={journeys} onJourneySelect={handleJourneySelect} />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default AdminPage;
