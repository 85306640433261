// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import layoutReducer from './slices/layoutSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    layout: layoutReducer,
  },
});
