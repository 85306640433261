import React, { useState } from 'react';
import { List, Typography, IconButton, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import BookMarkList from './BookMarkList';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const BookMark = ({ book, currentPartIndex, currentChapterIndex, selectChapter }) => {
  const headerHeight = useSelector(state => state.layout.headerHeight);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  const handleInfoClick = (description) => {
    if (isMobile) {
      setDialogContent(description);
      setOpen(true);
    }
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setShowTooltip(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDialogContent('');
  };

  return (
    <div
      style={{ width: '300px', marginTop: headerHeight }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box display="flex" alignItems="center" padding="16px">
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {book.title}
        </Typography>
        {!isMobile && showTooltip && (
          <Tooltip title={book.description} arrow>
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
        {isMobile && (
          <IconButton size="small" onClick={() => handleInfoClick(book.description)}>
            <InfoIcon />
          </IconButton>
        )}
      </Box>
      <List>
        <BookMarkList
          book={book}
          currentPartIndex={currentPartIndex}
          currentChapterIndex={currentChapterIndex}
          selectChapter={selectChapter}
          handleInfoClick={handleInfoClick}
        />
      </List>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Information</DialogTitle>
        <DialogContent>
          <Typography>{dialogContent}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BookMark;
