import React, { useState } from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';

const faqs = [
  {
    question: "What is Zarabyte?",
    answer: "Zarabyte is a platform that uses advanced AI to create personalized and dynamic reading experiences, adapting content to match your preferences as you read."
  },
  {
    question: "How does Zarabyte personalize content?",
    answer: "By analyzing your preferences and reading habits, Zarabyte generates content that evolves and adapts to keep your reading experience engaging and relevant."
  },
  {
    question: "How does the credit system work?",
    answer: "Zarabyte operates on a credit system based on usage of the OpenAI API. Users need to contact the admin to add credits, as there is no direct purchase option available yet."
  },
  {
    question: "What are the key features of Zarabyte?",
    answer: "Zarabyte offers personalized book creation, dynamic content updates, hierarchical bookmarks, and AI-assisted content management. Upcoming features include audiobook support and enhanced personal assistant capabilities."
  },
  {
    question: "How can I get access to Zarabyte?",
    answer: "Zarabyte is currently in beta. You can get access by contacting us through the website or via email to receive credits and start exploring the platform."
  },
  {
    question: "What makes Zarabyte's AI technology special?",
    answer: "Zarabyte leverages GPT-4 to dynamically generate personalized content. This integration allows for a seamless and evolving reading experience tailored to each user's journey."
  },
  {
    question: "What are Zarabyte's future plans?",
    answer: "We plan to expand our platform to include audiobook support, improve AI-driven content suggestions, and offer more personalized reading settings, such as adjustable font size and scrolling options."
  }
];

const FAQs = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ padding: '40px 20px', textAlign: 'center' }}>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
        FAQs
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion
          key={faq.question}
          expanded={expanded === index}
          onChange={handleChange(index)}
          sx={{ marginBottom: '10px' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box sx={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/faqs')}
          aria-label="More FAQs"
        >
          More FAQs
        </Button>
      </Box>
    </Box>
  );
};

export default FAQs;
